export const appointmentsStore = {
  namespaced: true,
  state: {
    appointments:[],
    appointment:{},
  },
  mutations: {
    setAppointmentSelected (state, payload) {
      state.appointment = payload
    },
    setAppointments (state, payload) {
      state.appointments = payload
    },
  },
  actions: {
    /* eslint-disable */
    registerAppointment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('appointment', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateAppointment ({ commit },[payload, idAppointments]) {
      return new Promise((resolve, reject) => {
        axios.post('appointment/'+idAppointments, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getAppointments ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('appointment')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            commit('setAppointments',res.data.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getAppointmentsSelected ({ commit }, idAppointments) {
      return new Promise((resolve, reject) => {
        axios.get('appointment/' + idAppointments)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            // commit("setAppointmentSelected",res.data.data.Appointment)
            // commit("patient/setPatientSelected",res.data.data.Patient, {root:true})
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    cancelAppointment ({ commit }, [idAppointment, data]) {
      return new Promise((resolve, reject) => {
        axios.post('cancel-appointment/'+idAppointment, data)
        .then(res => {
          if (res.status == 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}