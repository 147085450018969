import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import 'sweetalert2/dist/sweetalert2.min.css'
import renderTreatments from './plugins/renderTreatments'
import './assets/scss/fonts.scss'
import formData from './plugins/formData'
import permissions from './plugins/permissions'
import helpers from './plugins/helpers'
import VueEventBus from 'vue-event-bus'
import moment from  'moment'
import VueRouterUserRoles from 'vue-router-user-roles'

Vue.use(VueEventBus, {name:'$eventbus'})
Vue.use(VueSweetalert2)

Vue.config.productionTip = false
Vue.prototype.$moment = moment

store.dispatch('auth/currentAuthenticatedUser').then(() => {
  Vue.use(VueRouterUserRoles, { router })
  
  Promise.resolve({ role: store.state.auth.roleName }).then((user) => {
    Vue.prototype.$user.set(user)

    document.getElementById('loadingReloadPage').style.display = 'none'

    new Vue({
      store,
      router,
      vuetify,
      renderTreatments,
      formData,
      helpers,
      permissions,
      render: h => h(App)
    }).$mount('#app')
  })
})