import aparato_ortodóntico_fijo_bueno from '@/assets/img/odontogram/ap_ortodontico_fijo_bueno.png'
import implante from '@/assets/img/odontogram/implante.png';
import aparato_ortodóntico_fijo_malo from '@/assets/img/odontogram/ap_ortodontico_fijo_malo.png'
import macrodoncia from '@/assets/img/odontogram/macrodoncia.png';
import aparato_ortodóntico_removible_bueno from '@/assets/img/odontogram/ap_ortodontico_removible_bueno.png';
import microdoncia from '@/assets/img/odontogram/microdoncia.png';
import aparato_ortodóntico_removible_malo from '@/assets/img/odontogram/ap_ortodontico_removible_malo.png';
import migración from '@/assets/img/odontogram/migracion_derecha.png';
import caries from '@/assets/img/odontogram/caries.png';
import migración_izquierda from '@/assets/img/odontogram/migracion_izquierda.png';
import corona_definitiva from '@/assets/img/odontogram/corona_definitiva.png';
import movilidad from '@/assets/img/odontogram/movilidad.png';
import corona_definitiva_cc from '@/assets/img/odontogram/corona_definitiva_CC.png';
import corona_definitiva_cf from '@/assets/img/odontogram/corona_definitiva_CF.png';
import prótesis_fija_malo from '@/assets/img/odontogram/protesis_fija_mala.png';
import prótesis_fija_malo2 from '@/assets/img/odontogram/protesis_fija_mala2.png';
import prótesis_fija_malo3 from '@/assets/img/odontogram/protesis_fija_mala3.png';
import corona_definitiva_cj from '@/assets/img/odontogram/corona_definitiva_CJ.png';
import prótesis_removible_bueno from '@/assets/img/odontogram/protesis_removible_bueno.png';
import corona_definitiva_cmc from '@/assets/img/odontogram/corona_definitiva_CMC.png';
import prótesis_removible_malo from '@/assets/img/odontogram/protesis_removible_malo.png';
import corona_definitiva_cv from '@/assets/img/odontogram/corona_definitiva_CV.png';
import prótesis_total_buena from '@/assets/img/odontogram/protesis_total_buena.png';
import corona_temporal from '@/assets/img/odontogram/corona_temporal.png';
import prótesis_total_malo from '@/assets/img/odontogram/protesis_total_malo.png';
import desgaste_oclusal from '@/assets/img/odontogram/desgaste_oclusal.png';
import remanente_radicular from '@/assets/img/odontogram/remanente_radicular.png';
import diastema from '@/assets/img/odontogram/diastema.png';
import restauración from '@/assets/img/odontogram/restauracion.png';
import diente_ausente from '@/assets/img/odontogram/diente_ausente.png';
import diente_extraído from '@/assets/img/odontogram/diemte_extraido.png';
import restauración_am from '@/assets/img/odontogram/restauracion_AM.png';
import diente_discrómico from '@/assets/img/odontogram/diente_discromico.png';
import restauración_ie from '@/assets/img/odontogram/restauracion_IE.png';
import diente_ectópico from '@/assets/img/odontogram/diente_ectopico.png';
import restauración_im from '@/assets/img/odontogram/restauracion_IM.png';
import diente_en_clavija from '@/assets/img/odontogram/diente_en_clavija.png';
import restauración_iv from '@/assets/img/odontogram/restauracion_IV.png';
import diente_extruido from '@/assets/img/odontogram/diente_extruido_arriba.png';
import restauración_r from '@/assets/img/odontogram/restauracion_R.png';
import diente_extruido_arriba from '@/assets/img/odontogram/diente_extruido_arriba.png';
import restauración_temporal from '@/assets/img/odontogram/restauracion_temporal.png';
import diente_intruido from '@/assets/img/odontogram/diente_intruido_abajo - copia.png';
import semi_impactación from '@/assets/img/odontogram/semi_impactacion.png';
import diente_intruido_arriba from '@/assets/img/odontogram/diente_intruido_arriba - copia.png';
import supernumerario from '@/assets/img/odontogram/supernumerario.png';
import edéntulo_total from '@/assets/img/odontogram/edentulo_total.png';
import transposición from '@/assets/img/odontogram/transposicion_abajo.png';
import fractura from '@/assets/img/odontogram/fractura.png';
import transposición_arriba from '@/assets/img/odontogram/transposicion_arriba.png';
import geminación_fusión from '@/assets/img/odontogram/geminacion_fusion.png';
import tratamiento_pulpar from '@/assets/img/odontogram/tratamiento_pulpar.png';
import giroversión from '@/assets/img/odontogram/giroversion_derecha.png';
import tratamiento_pulpar_pc from '@/assets/img/odontogram/tratamiento_pulpar_PC.png';
import giroversión_izquierda from '@/assets/img/odontogram/giroversion_izquierda.png';
import tratamiento_pulpar_pp from '@/assets/img/odontogram/tratamiento_pulpar_PP.png';
import impactación from '@/assets/img/odontogram/impactacion.png';
import tratamiento_pulpar_tc from '@/assets/img/odontogram/tratamiento_pulpar_TC.png';
import ordontico_fijo_bueno2 from '@/assets/img/odontogram/ordontico_fijo_bueno2.png';
import ordontico_fijo_bueno from '@/assets/img/odontogram/ordontico_fijo_bueno.png';
import ordontico_fijo_bueno3 from '@/assets/img/odontogram/ordontico_fijo_bueno3.png';
import ordontico_fijo_malo2 from '@/assets/img/odontogram/ordontico_fijo_malo2.png';
import ordontico_fijo_malo from '@/assets/img/odontogram/ordontico_fijo_malo.png';
import ordontico_fijo_malo3 from '@/assets/img/odontogram/ordontico_fijo_malo3.png';
import perno_bueno from '@/assets/img/odontogram/perno_bueno.png';
import perno_bueno_mf from '@/assets/img/odontogram/perno_bueno_mf.png';
import perno_bueno_mm from '@/assets/img/odontogram/perno_bueno_mm.png';
import perno_mf from '@/assets/img/odontogram/perno_mf.png';
import perno_mm from '@/assets/img/odontogram/perno_mm.png';
import perno_malo from '@/assets/img/odontogram/perno_malo.png';
import prótesis_fija_buena from '@/assets/img/odontogram/protesis_fija_buena.png';
import prótesis_fija_buena2 from '@/assets/img/odontogram/protesis_fija_buena2.png';
import prótesis_fija_buena3 from '@/assets/img/odontogram/protesis_fija_buena3.png';
import sellante_bueno from '@/assets/img/odontogram/sellante_bueno.png';
import sellante_malo from '@/assets/img/odontogram/sellante_malo.png';
import tratamiento_pulpar_malo from '@/assets/img/odontogram/trat_pulpar_malo.png';
import corona_temporal_cc from '@/assets/img/odontogram/corona_temporal_cc.png';
import corona_temporal_cf from '@/assets/img/odontogram/corona_temporal_cf.png';
import corona_temporal_cj from '@/assets/img/odontogram/corona_temporal_cj.png';
import corona_temporal_cmc from '@/assets/img/odontogram/corona_temporal_cmc.png';
import corona_temporal_cv from '@/assets/img/odontogram/corona_temporal_cv.png';
import tratamiento_pulpar_pc_r from '@/assets/img/odontogram/tratamiento_pulpar_pc_r.png';
import tratamiento_pulpar_pp_r from '@/assets/img/odontogram/tratamiento_pulpar_pp_r.png';
import tratamiento_pulpar_tc_r from '@/assets/img/odontogram/tratamiento_pulpar_tc_r.png';
import restauración_am_r from '@/assets/img/odontogram/restauracion_am_r.png';
import restauración_ie_r from '@/assets/img/odontogram/restauracion_ie_r.png';
import restauración_im_r from '@/assets/img/odontogram/restauracion_im_r.png';
import restauración_iv_r from '@/assets/img/odontogram/restauracion_IV_r.png';
import restauración_r_r from '@/assets/img/odontogram/restauracion_r_r.png';

const images = {
	aparato_ortodóntico_fijo_bueno,
	implante,
	aparato_ortodóntico_fijo_malo,
	macrodoncia,
	aparato_ortodóntico_removible_bueno,
	microdoncia,
	aparato_ortodóntico_removible_malo,
	migración,
	caries,
	migración_izquierda,
	corona_definitiva,
	movilidad,
	corona_definitiva_cc,
	corona_definitiva_cf,
	prótesis_fija_malo,
	prótesis_fija_malo2,
	prótesis_fija_malo3,
	corona_definitiva_cj,
	prótesis_removible_bueno,
	corona_definitiva_cmc,
	prótesis_removible_malo,
	corona_definitiva_cv,
	prótesis_total_buena,
	corona_temporal,
	prótesis_total_malo,
	desgaste_oclusal,
	remanente_radicular,
	diastema,
	restauración,
	diente_ausente,
	diente_extraído,
	restauración_am,
	diente_discrómico,
	restauración_ie,
	diente_ectópico,
	restauración_im,
	diente_en_clavija,
	restauración_iv,
	diente_extruido,
	restauración_r,
	diente_extruido_arriba,
	restauración_temporal,
	diente_intruido,
	semi_impactación,
	diente_intruido_arriba,
	supernumerario,
	edéntulo_total,
	transposición,
	fractura,
	transposición_arriba,
	geminación_fusión,
	tratamiento_pulpar,
	giroversión,
	tratamiento_pulpar_pc,
	giroversión_izquierda,
	tratamiento_pulpar_pp,
	impactación,
	tratamiento_pulpar_tc,
	ordontico_fijo_bueno2,
	ordontico_fijo_bueno,
	ordontico_fijo_bueno3,
	ordontico_fijo_malo2,
	ordontico_fijo_malo,
	ordontico_fijo_malo3,
	perno_bueno,
	perno_bueno_mf,
	perno_bueno_mm,
	perno_mf,
	perno_mm,
	perno_malo,
	prótesis_fija_buena,
	prótesis_fija_buena2,
	prótesis_fija_buena3,
	sellante_bueno,
	sellante_malo,
	tratamiento_pulpar_malo,
	corona_temporal_cc,
	corona_temporal_cf,
	corona_temporal_cj,
	corona_temporal_cmc,
	corona_temporal_cv,
	tratamiento_pulpar_pc_r,
	tratamiento_pulpar_pp_r,
	tratamiento_pulpar_tc_r,
	restauración_am_r,
	restauración_ie_r,
	restauración_im_r,
	restauración_iv_r,
	restauración_r_r,
}

export {images}