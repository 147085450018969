export const LaboratoriesRoutes = [
  {
    path: '/laboratory',
    name: 'laboratory',
    component: () => import(/* webpackChunkName: "Laboratory Index" */ '@/views/laboratory/index.vue'),
    redirect: {name: 'laboratory.list'},
    children: [
      {
        path: '/',
        name: 'laboratory.list',
        component: () => import(/* webpackChunkName: "Laboratory List" */ '@/views/laboratory/Laboratory.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'order',
        name: 'laboratory.order',
        component: () => import(/* webpackChunkName: "Laboratory Ordert" */ '@/views/laboratory/Order.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      }
    ]
  },
]