export const patientStore = {
  namespaced: true,
  actions: {
    /* eslint-disable */
    getPatientOdontogramById: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios('odontogram/' + payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    createOdontogram: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        axios.post('odontogram', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getTheHistoryOfOdontogramsByDate: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        const { id, date } = payload
        axios(`find-odontogram/${id}/${date}`)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getPatients ({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios.get('patients?page=' + page)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // buscar a un paciente en el buscador de un datatable
    searchPatient ({ commit }, [text, page]) {
      return new Promise((resolve, reject) => {
        axios.get('searchPatient/'+text+'?page='+page)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // buscar a un paciente por su dni al momento de crear un cita
    getPatientByDni ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('find-patient/' + payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getPatientImage ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('get-image-patients/' + payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    registerPatient ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('patients',payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updatePatient ({ commit }, [payload, idPatient]) {
      return new Promise((resolve, reject) => {
        axios.post('patients/' + idPatient, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // presupuestos de un paciente en especifico
    getBudgets ({ commit }, idBudget) {
      return new Promise((resolve, reject) => {
        axios.get('budget/' + idBudget)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getPatientById({ commit }, idPatient) {
      return new Promise((resolve, reject) => {
        axios.get('patients/' + idPatient)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    generateBudget ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('budget', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    // para ver los detalles de un presupuesto
    getBudgetDetails ({ commit }, idBudget) {
      return new Promise((resolve, reject) => {
        axios.get('budget-details/'+ idBudget)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    generatePayFee ({ commit },payload) {
      return new Promise((resolve, reject) => {
        axios.post('pay-fee', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  }
}