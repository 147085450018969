import Vue from 'vue'
import { treatments } from './globals';

const plugin = {
  install() {
    //images
    const getImageTreatmentOrMaterial = (treatment, material = '') => {
      if (material) {
        return treatments[treatment].materials[material]
      }
      return treatments[treatment]
    }
    //type fracture
    Vue.prototype.$toPaintFracture = (treatmentName, identify, type) => {
      const getElementWrapTooth = document.querySelectorAll(`.wrap-tooth-${identify}`)
      const fractContainer = document.getElementById(`fractura-container-${identify}`)
      
      if (fractContainer) {
        const newElementFractureImg = document.createElement('img')
        newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementFractureImg.className = `img-fluid ${type}`            
        newElementFractureImg.id = `fractura-${type}-${identify}` 
  
        fractContainer.appendChild(newElementFractureImg)
        getElementWrapTooth[0].appendChild(fractContainer)
      } else {
        const newElementFractureContainer = document.createElement('div')
        newElementFractureContainer.className = `state ${treatmentName}`
        newElementFractureContainer.id = `fractura-container-${identify}`

        const newElementFractureImg = document.createElement('img')
        newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementFractureImg.className = `img-fluid ${type}`
        newElementFractureImg.id = `fractura-${type}-${identify}`
  
        newElementFractureContainer.appendChild(newElementFractureImg)
        getElementWrapTooth[0].appendChild(newElementFractureContainer)
      }

      if (getElementWrapTooth.length > 1) {
        const fractContainerPreview = document.getElementById(`fractura-container-${identify}-preview`)
        if (fractContainerPreview) {
          const newElementFractureImg = document.createElement('img')
          newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
          newElementFractureImg.className = `img-fluid ${type}`
          newElementFractureImg.id = `fractura-${type}-${identify}-preview` 
    
          fractContainerPreview.appendChild(newElementFractureImg)
          getElementWrapTooth[1].appendChild(fractContainerPreview)
        } else {
          const newElementFractureContainer = document.createElement('div')
          newElementFractureContainer.className = `state ${treatmentName}`
          newElementFractureContainer.id = `fractura-container-${identify}-preview`

          const newElementFractureImg = document.createElement('img')
          newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
          newElementFractureImg.className = `img-fluid ${type}`
          newElementFractureImg.id = `fractura-${type}-${identify}-preview` 
    
          newElementFractureContainer.appendChild(newElementFractureImg)
          getElementWrapTooth[1].appendChild(newElementFractureContainer)
        }
      }
    }

    //type fracture preview
    Vue.prototype.$toPaintFracturePreview = (treatmentName, identify, type) => {
      const getElementWrapTooth = document.querySelectorAll(`.wrap-tooth-${identify}`)
      const fractContainerPreview = document.getElementById(`fractura-container-${identify}-preview`)

      if (fractContainerPreview) {
        const newElementFractureImg = document.createElement('img')
        newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementFractureImg.className = `img-fluid ${type}`
        newElementFractureImg.id = `fractura-${type}-${identify}-preview` 
  
        fractContainerPreview.appendChild(newElementFractureImg)
        getElementWrapTooth[1].appendChild(fractContainerPreview)
      } else {
        const newElementFractureContainer = document.createElement('div')
        newElementFractureContainer.className = `state ${treatmentName}`
        newElementFractureContainer.id = `fractura-container-${identify}-preview`

        const newElementFractureImg = document.createElement('img')
        newElementFractureImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementFractureImg.className = `img-fluid ${type}`
        newElementFractureImg.id = `fractura-${type}-${identify}-preview` 
  
        newElementFractureContainer.appendChild(newElementFractureImg)
        getElementWrapTooth[1].appendChild(newElementFractureContainer)
      }
  }

    // type parts
    Vue.prototype.$toPaintParts = (treatmentName, element) => {
      const getElement = document.querySelectorAll(`.${element}`)

      getElement.forEach((part, i) => {
        const newElement = document.createElement('div')
        newElement.className = `state ${treatmentName}`
        newElement.id = (i > 0) ? `state_${treatmentName}_${element}_preview` : `state_${treatmentName}_${element}`

        const newElementImg = document.createElement('img')
        newElementImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementImg.className = 'img-fluid'

        newElement.appendChild(newElementImg)
        part.appendChild(newElement)
      })
    }

    // type preview
    Vue.prototype.$toPaintPartsPreview = (treatmentName, element) => {
      const getElement = document.querySelectorAll(`.${element}`)
      const newElement = document.createElement('div')
      newElement.className = `state ${treatmentName}`
      newElement.id = `state_${treatmentName}_${element}_preview`

      const newElementImg = document.createElement('img')
      newElementImg.src = getImageTreatmentOrMaterial(treatmentName).image
      newElementImg.className = 'img-fluid'

      newElement.appendChild(newElementImg)
      getElement[1].appendChild(newElement)
    }

    // type square
    Vue.prototype.$toPaintSquare = (identify, numberSquare, treatment, material = null) => {
      if(!numberSquare) return

      const squareContainer = document.getElementById(`${identify}-square-${numberSquare}`)
      const newSquare = document.createElement('div')

      const imgSquare = document.createElement('img')
      //imgSquare.src = treatmentImage
      imgSquare.src = material ? getImageTreatmentOrMaterial(treatment, material).image :getImageTreatmentOrMaterial(treatment).image
      imgSquare.className = 'img-fluid'

      newSquare.appendChild(imgSquare)

      squareContainer.appendChild(newSquare)
    }

    // type unique
    Vue.prototype.$toPaintUnique = (identify, treatmentName) => {
      // agrega la clase has-margin-right para los dientes de esta lista
      const hasMarginRightClass = ['11', '51', '81', '41']
      const elementCreated = document.querySelectorAll(`.wrap-tooth-${identify}`)

      elementCreated.forEach((element, i) => {
        const newElement = document.createElement('div')
        newElement.id = (i > 0) ? `state_${treatmentName}_${identify}_preview` : newElement.id = `state_${treatmentName}_${identify}`

        if (hasMarginRightClass.includes(identify)) {
          newElement.className = `state ${treatmentName} has-margin-right`
        } else {
          newElement.className = `state ${treatmentName}`
        }

        const newElementImg = document.createElement('img')
        newElementImg.src = getImageTreatmentOrMaterial(treatmentName).image
        newElementImg.className = 'img-fluid'

        newElement.appendChild(newElementImg)
        element.appendChild(newElement)
      })
    }

    // type unique preview
    Vue.prototype.$toPaintUniquePreview = (identify, treatmentName) => {
      // agrega la clase has-margin-right para los dientes de esta lista
      const hasMarginRightClass = ['11', '51', '81', '41']
      const elementCreated = document.querySelectorAll(`.wrap-tooth-${identify}`)

      const newElement = document.createElement('div')
      newElement.id = `state_${treatmentName}_${identify}_preview`

      if (hasMarginRightClass.includes(identify)) {
        newElement.className = `state ${treatmentName} has-margin-right`
      } else {
        newElement.className = `state ${treatmentName}`
      }

      const newElementImg = document.createElement('img')
      newElementImg.src = getImageTreatmentOrMaterial(treatmentName).image
      newElementImg.className = 'img-fluid'

      newElement.appendChild(newElementImg)
      elementCreated[1].appendChild(newElement)
    }

    // type range
    Vue.prototype.$toPaintRange = (identify, treatmentName, startOrMiddleOrEndImage, hasMarginRight, nose) => {
      let className = `state ${treatmentName} range`
      let image = null

      if (startOrMiddleOrEndImage === 'start image') {
        image = getImageTreatmentOrMaterial(treatmentName).start
      } else if (startOrMiddleOrEndImage === 'final image') {
        image = getImageTreatmentOrMaterial(treatmentName).end
      } else {
        image = getImageTreatmentOrMaterial(treatmentName).image
      }

      if(hasMarginRight) {
        className = `state ${treatmentName} has-margin-right range`
      }

      const elementCreated = document.querySelector(`.wrap-tooth-${identify}`)

      const newElement = document.createElement('div')
      newElement.className = className
      newElement.id = `state_range_${treatmentName}_${identify}`

      const newElementImg = document.createElement('img')
      newElementImg.src = image
      newElementImg.className = 'img-fluid'
      newElement.appendChild(newElementImg)

      if (hasMarginRight) {
        const newElementImg2 = document.createElement('img')
        newElementImg2.src = nose ? getImageTreatmentOrMaterial(treatmentName).image : image
        newElementImg2.className = 'img-fluid'
        newElement.appendChild(newElementImg2)
      }

      elementCreated.appendChild(newElement)
    }
  }
}

Vue.use(plugin)

export default plugin;