import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { authStore } from './modules/auth'
import { odontogramStore } from './modules/odontogram'
import { patientStore } from './modules/patient'
import { conventionStore } from './modules/convention'
import { consultoryStore } from './modules/consultory'
import { appointmentsStore } from './modules/appointments'
import { laboratoryStore } from './modules/laboratory'
import { kardexStore } from './modules/kardex'
import { usersStore } from './modules/user'
import { rolesStore } from './modules/role'
import { doctorsStore } from './modules/doctor'
import { specialtysStore } from './modules/specialty'
import { treatmentsStore } from './modules/settings/treatments'
import { historyStore } from './modules/history'

export default new Vuex.Store({
  state: {
    // responsive web design 
    isTablet: false,
    loading: 0,
    loadingRouter: false,
    snackbar: {
      msg: '',
      showSnackbar: false,
      color: 'black',
      colorTextClose: 'white'
    },
    breadcrumbs: [
      {
        text: 'Dashboard',
        disabled: false,
        to: { name: 'dashboard' },
      }
    ],
  },
  getters: {},
  mutations: {
    setIsTablet (state, payload) {
      state.isTablet = payload
    },
    startLoading(state) {
      state.loading++
    },
    setLoadingRouter(state, payload) {
      state.loadingRouter = payload
    },
    setShowSnackbar (state, payload) {
      state.snackbar = {
        msg: payload.msg || '',
        showSnackbar: payload.showSnackbar || false,
        color: payload.color || 'black',
        colorTextClose: payload.colorTextClose || 'white'
      }
    },
    finishLoading (state) {
      if (state.loading > 0) {
        state.loading--
      }
    },
    setBreadCrumbs (state, payload) {
      state.breadcrumbs = payload
    },
  },
  actions: {},
  modules: {
    auth:{ ...authStore },
    odontogram: { ...odontogramStore },
    patient: { ...patientStore },
    convention: { ...conventionStore },
    consultory: { ...consultoryStore },
    appointment: { ...appointmentsStore },
    laboratory: { ...laboratoryStore },
    kardex: { ...kardexStore },
    user: { ...usersStore },
    role: { ...rolesStore },
    doctor: { ...doctorsStore },
    specialty: { ...specialtysStore },
    setting: { ...treatmentsStore },
    history: { ...historyStore },
  }
})
