<template>
  <v-app class="font-nunito">
    <v-main>
      <v-container fluid>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="primary"
              large
              class="mt-3 mr-3"
              style="position:absolute; top: 0; right: 0;"
              v-bind="attrs"
              v-on="on"
              @click="logout()"
            >
              <v-icon large>mdi-power-settings</v-icon>
            </v-btn>
          </template>
          <span>Cerrar Sesión</span>
        </v-tooltip>
        <v-row>
          <v-col cols="12">
            <v-row class="justify-center">
              <v-col md="4" sm="4">
                <!-- <v-avatar color="red">
                  <span class="white--text text-h5">CJ</span>
                </v-avatar> -->
                <v-avatar width="100" style="height:100px" class="mx-auto d-block">
                  <img
                    :src="$store.state.auth.authenticatedUser.user[0].url"
                    :alt="$store.state.auth.authenticatedUser.user[0].last_name" style="object-fit: cover;">
                </v-avatar>
                <span class="pt-3 d-block primary--text" style="text-align:center">
                  ¡Hola
                  <strong class="font-weigth-bold" v-if="Object.keys($store.state.auth.authenticatedUser).length > 0">
                    {{$store.state.auth.authenticatedUser.user[0].name}} {{$store.state.auth.authenticatedUser.user[0].last_name}}
                  </strong>! <!-- no working bold -->
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="justify-center">
              <v-col md="3" sm="6">
                <v-select
                  :items="$store.state.auth.authenticatedUser.consultory"
                  item-text="name"
                  item-value="idConsultory"
                  placeholder="Seleccione un Consutorio"
                  v-model="selectedConsultory"
                  no-data-text="No hay datos que mostrar"
                ></v-select>
                <small class="secondary--text d-block mt-n3 mb-3">Es necesario que seleccione un consultorio *</small>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row class="justify-center">
              <v-col v-if="$can('agenda')" md="2" sm="3">
                <v-sheet
                  :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                  height="80"
                  width="140"
                  class="ml-auto d-flex flex-column justify-center align-center rounded-lg on-hover c-pointer"
                  @click="redirect('diary')"
                >
                  <v-icon class="white--text" x-large>mdi-calendar-blank</v-icon>
                  <h5 class="white--text">Agenda</h5>
                </v-sheet>
              </v-col>
              <v-col md="2" sm="3">
                <v-sheet
                  :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                  height="80"
                  width="140"
                  class="mx-auto d-flex flex-column justify-center align-center rounded-lg on-hover c-pointer"
                  @click="redirect('users')"
                >
                  <v-icon class="white--text" x-large>mdi-book</v-icon>
                  <h5 class="white--text">Administración</h5>
                </v-sheet>
              </v-col>
              <!-- <v-col md="2" sm="3">
                <v-hover
                  v-slot="{ hover }"
                  open-delay="200"
                  :disabled="selectedConsultory ? false : true"
                >
                  <v-sheet
                    :elevation="hover ? 22 : 2"
                    :class="{ 'on-hover c-pointer': hover }"
                    :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                    height="80"
                    width="140"
                    class="mr-auto d-flex flex-column justify-center align-center rounded-lg"
                  >
                    <v-icon class="white--text" style="font-size:30px;transform: scaleX(-1);">mdi-cash-multiple</v-icon>
                    <h5 class="white--text">Caja</h5>
                  </v-sheet>
                </v-hover>
              </v-col> -->
            </v-row>
            <v-row class="justify-center">
              <v-col v-if="$can('paciente')" md="2" sm="3">
                <v-sheet
                  :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                  height="80"
                  width="140"
                  class="ml-auto d-flex flex-column justify-center align-center rounded-lg on-hover c-pointer"
                  @click="redirect('patient')"
                >
                  <v-icon class="white--text" x-large>mdi-calendar-account</v-icon>
                  <h5 class="white--text">Paciente</h5>
                </v-sheet>
              </v-col>
              <v-col v-if="$can('configuraciones')" md="2" sm="3">
                <v-sheet
                  :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                  height="80"
                  width="140"
                  class="mx-auto d-flex flex-column justify-center align-center rounded-lg on-hover c-pointer"
                  @click="redirect('setting.treatment')"
                >
                  <v-icon class="white--text" style="font-size:30px;transform: rotate(180deg);">mdi-cogs</v-icon>
                  <h5 class="white--text mx-auto" style="width:110px;text-align: center;">Configuración  &nbsp;y Soporte</h5>
                </v-sheet>
              </v-col>
              <!-- <v-col md="2" sm="3">
                <v-hover
                  v-slot="{ hover }"
                  open-delay="200"
                  :disabled="selectedConsultory ? false : true"
                >
                  <v-sheet
                    :elevation="hover ? 22 : 2"
                    :class="{ 'on-hover c-pointer': hover }"
                    :color="selectedConsultory ? 'primary': 'grey lighten-1'"
                    height="80"
                    width="140"
                    class="mr-auto d-flex flex-column justify-center align-center rounded-lg"
                  >
                    <v-icon class="white--text" style="font-size:30px;transform: scaleX(-1);">mdi-poll</v-icon>
                    <h5 class="white--text">Reportes</h5>
                  </v-sheet>
                </v-hover>
              </v-col> -->
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "login-view",
  data:() => ({
    selectedConsultory: null
  }),
  beforeCreate() {
    if(!localStorage.getItem('domain')) {
      location.href = '/auth'

    } else if(!localStorage.getItem('jwt')) {
      location.href = '/login'
    }
  },
  methods: {
    redirect(route) {
      if (this.selectedConsultory) {
        const consultory = this.$store.state.auth.authenticatedUser.consultory.filter((consultory) => consultory.idConsultory === this.selectedConsultory)[0]

        localStorage.setItem('consultory', JSON.stringify(consultory))
        this.$store.commit('auth/setSelectedConsultory', consultory)

        setTimeout(() => {
          if (this.$can('usuarios')) {
            this.$router.push({name: route})
            return
          }
          this.$router.push({name: 'consultory'})
        }, 300);
      }
    },
    logout() {
      if (localStorage.getItem('jwt')) {
        const token = localStorage.getItem('jwt')
        this.$store.dispatch('auth/logout', token).then(() => {})
      } else {
        location.href = '/login'
      }
    }
  }
};
</script>

<style lang="scss">
.c-pointer {
  cursor:pointer
}
.on-hover:hover {
  box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 15%);
}
.container {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tagline {
  font-size: 32px;
  font-weight: 800!important;
  letter-spacing: -.5px;
  background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.btn-login {
  background-image: linear-gradient(to right, #647eff 0%, #42d392  51%, #647eff  100%);
  margin: 10px;
  padding: 15px 45px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white !important;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: block;
}

.btn-login:hover {
  background-position: right center;
}

.field {
  width: 250px!important;
}
.no-decoration {
  text-decoration: none;
}
.networks {
  display: flex;
  align-items: center;
}
.network-logo {
  margin-left: 3px;
  margin-right: 3px;
  width: 32px;
  height: 32px;
  transition: all 6ms;
  &:hover {
    transform: scale(1.1);
  }
}
.network-logo-whatsapp {
  width: 44px;
  height: 44px;
  transition: all 6ms;
  &:hover {
    transform: scale(1.1);
  }
}
</style>
