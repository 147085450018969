export const kardexStore = {
  namespaced: true,
  actions: {
    /* eslint-disable */
    getKardex ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('inventory')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getKardexById ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('inventory/'+ id)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    createProduct ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('inventory', payload)
        .then(res => {
          resolve(res.data)
          if (res.status === 200) {
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    addConsultoriesToThisProduct ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('inventory/add-consultory', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateProduct ({ state, commit }, [payload, id]) {
      return new Promise((resolve, reject) => {
        axios.post('inventory/' + id, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}