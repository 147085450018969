export const UsersRoutes = [
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "Users" */ '@/views/User.vue'),
    meta: {
      requiresAuth: true,
      permissions: [
        {
          role: "ADMINISTRADOR",
          access: true
        },
        {
          role: "ASISTENTE ADMINISTRADOR",
          access: true,
        },
        {
          role: "ASISTENTE MÉDICO",
          access: false,
          redirect: "home"
        },
        {
          role: "DOCTOR",
          access: false,
          redirect: "home"
        },
        {
          role: "UNAUTHORIZED",
          access: false,
          redirect: "login"
        }
      ]
    }
  },
]