export const PatientsRoutes = [
  {
    path: '/patient',
    name: 'patient',
    component: () => import(/* webpackChunkName: "Patient Index" */ '@/views/patient/index.vue'),
    redirect: {name: 'patient.list'},
    children: [
      {
        path: '/',
        name: 'patient.list',
        component: () => import(/* webpackChunkName: "Patient List" */ '@/views/patient/Patient.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'generate-budget/:id/from-the-odontogram', //id patient
        name: 'patient.generate-budget',
        component: () => import(/* webpackChunkName: "Patient Generate Budget" */ '@/views/patient/budget/GenerateBudget.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'generate-budget/:id/create-budget-manually', //id patient
        name: 'patient.create-budget-manually',
        component: () => import(/* webpackChunkName: "Patient Create Budget Manually" */ '@/views/patient/budget/CreateBudgetManually.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'list-budgets/:id', //id patient
        name: 'patient.list-budgets',
        component: () => import(/* webpackChunkName: "Patient List Budget" */ '@/views/patient/budget/ListBudgets'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'invoice/',
        name: 'patient.invoice',
        component: () => import(/* webpackChunkName: "Patient Invoice" */ '@/views/patient/budget/Invoice.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: 'history/:id',
        name: 'patient.history',
        component: () => import(/* webpackChunkName: "Patient History" */ '@/views/patient/History.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
    ]
  },
]