import Vue from 'vue'
import store from '../store'

const plugin = {
  install() {
    Vue.prototype.$formData = (elements) => {
      let form_data = new FormData()
      form_data.append('idUserAuth', store.state.auth.authenticatedUser.user[0].idUser)

      for ( let key in elements ) {
        form_data.append(key, elements[key])
      }

      return form_data
    },
    Vue.prototype.$formDataUpdate = (elements) => {
      let form_data = new FormData()
      form_data.append('_method','PUT')
      form_data.append('idUserAuth', store.state.auth.authenticatedUser.user[0].idUser)

      for ( let key in elements ) {
        form_data.append(key, elements[key]);
      }
      return form_data
    }
  } 
}

Vue.use(plugin)

export default plugin;