"use strict";

import Vue from 'vue'
import axios from 'axios'
import store from '@/store/'
let token = ''

if (localStorage.getItem('jwt')) {
  token = `Bearer ${localStorage.getItem('jwt')}`
}

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.headers.common['Authorization'] = token;
// axios.defaults.headers.common['Domain'] = localStorage.getItem('domain') || ''
// axios.defaults.headers.common['Domain'] = store.state.auth.domain

let config = {
  baseURL: process.env.VUE_APP_APIURL
 // baseURL: process.env.VUE_APP_APIURL // || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    store.commit('startLoading');
    return config;
  },
  function(error) {
    // Do something with request error
    store.commit('finishLoading');
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    store.commit('finishLoading');
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      let msg = 'Su sesión a expirado, por favor inicie sesión nuevamente.'

      if (error.response.data.data.user === 'credenciales incorrectas') {
        return Promise.reject(error);

      } else if (error.response.data.data.user === 'Invalid client') {
        msg = 'Cliente no encontrado, por favor autentíquese nuevamente.'
      }

      Vue.swal({
        icon: 'info',
        title: 'Ops!',
        text: msg,
        confirmButtonColor: '#16b1ff',
        confirmButtonText: 'Entendido',
        allowOutsideClick: false,
      }).then(() => {
        window.location = '/login';
      })

      store.commit('finishLoading');
      return new Promise(() => {});

    } else if (error.response.status === 400) {
      Vue.swal({
        icon: 'info',
        title: 'Ops!',
        text: 'A sucedido un error por favor intentelo nuevamente.',
        confirmButtonColor: '#16b1ff',
        confirmButtonText: 'Entendido',
      })

      store.commit('finishLoading');
      return new Promise(() => {});
    } else if (error.response.status === 406) {
      Vue.swal({
        icon: 'info',
        title: 'Ops!',
        text: 'No se pudo procesar su solicitud.',
        confirmButtonColor: '#16b1ff',
        confirmButtonText: 'Entendido',
      })

      store.commit('finishLoading');
      return new Promise(() => {});
    } else {
      // Do something with response error
      store.commit('finishLoading');
      return Promise.reject(error);
    }
  }
);

Plugin.install = function(Vue, /*options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
