<template>
  <div class="networks">
    <a href="https://www.facebook.com/kirudentsoft" target="_blank" class="network-button">
      <div class="icon facebook"></div>
      <span class="title-network font-nunito">FACEBOOK</span>
    </a>
    <a href="https://www.youtube.com/channel/UCkJ98TU8BnZMixIJ1RJCVJw" target="_blank" class="network-button">
      <div class="icon youtube"></div>
      <span class="title-network font-nunito">YOUTUBE</span>
    </a>
    <a href="https://www.linkedin.com/company/kiru-dent-software-odontol%C3%B3gico" target="_blank" class="network-button">
      <div class="icon linkedin"></div>
      <span class="title-network font-nunito">LINKEDIN</span>
    </a>
    <a href="#" target="_blank" class="network-button">
      <div class="icon whatsapp"></div>
      <span class="title-network font-nunito">WHATSAPP</span>
    </a>
  </div>
</template>

<script>
  export default {
    name: 'component-network'
  }
</script>

<style lang="scss">
.networks {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;

  .network-button {
    position: relative;
    height: 40px;
    width: 40px;
    background: var(--white);
    box-shadow: 0 15px 50px rgba(0,0,0,0.05);
    overflow: hidden;
    border-radius: 50px;
    transition: 0.5s;
    text-decoration: none;

    &:hover {
      width: 150px;
    }

    .icon {
      display: inline-block;
      height: 100%;
      width: 40px;
      border-radius: 40px;
      box-sizing: border-box;
      text-align: center;
      line-height: 50px;
      box-shadow: 0 15px 50px rgba(0,0,0,0.3);
      background-repeat: no-repeat;
      background-size: contain;

    }
    .facebook {background-image: url('~@/assets/img/networks/kiru-08.png');}
    .youtube {background-image: url('~@/assets/img/networks/kiru-07.png');}
    .linkedin {background-image: url('~@/assets/img/networks/kiru-09.png');}
    .whatsapp {background-image: url('~@/assets/img/networks/kiru-10.png');}

    .title-network {
      color: var(--secondary);
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.1em;
      transition: 0.5s;
      transition-delay: 0s;
      margin-left: 5px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>