import Vue from "vue";

const plugin = {
  install() {
    Vue.prototype.$parseDecimal = (valor) => {
      valor = parseFloat(valor)
      if (valor > 999) {
        // console.log('valor: ', valor, typeof valor)
        let nuevoValor = valor.toFixed('2').split('.') ///1000.00 with split -> ['1000', '00']
        // console.log('nuevoValor: ', nuevoValor)
        if (nuevoValor.length > 1 && nuevoValor[1] != '00') {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(',') + '.' + nuevoValor[1]
        } else if (nuevoValor.length > 1 && nuevoValor[1] == '00') {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(',')
        } else {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(',')
        }

        return nuevoValor
      } else {
        return valor.toFixed('2')
      }
    },
    Vue.prototype.$parseDecimalSpace = (valor) => {
      valor = parseFloat(valor)
      if (valor > 999) {
        // console.log('valor: ', valor, typeof valor)
        let nuevoValor = valor.toFixed('2').split('.')
        // console.log('nuevoValor: ', nuevoValor)
        if (nuevoValor.length > 1 && nuevoValor[1] != '00') {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(' ') + '.' + nuevoValor[1]
        } else if (nuevoValor.length > 1 && nuevoValor[1] == '00') {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(' ')
        } else {
          nuevoValor = nuevoValor[0].split(/(?=(?:\d{3})+$)/).join(' ')
        }
        return nuevoValor
      } else {
        return valor.toFixed('2')
      }
    }
  }
}

Vue.use(plugin)

export default plugin;