export const DiaryRoutes = [
  {
    path: '/diary',
    name: 'diary',
    component: () => import(/* webpackChunkName: "Diary" */ '@/views/Diary.vue'),
    meta: {
      requiresAuth: true,
      permissions: [
        {
          role: "ADMINISTRADOR",
          access: true
        },
        {
          role: "ASISTENTE ADMINISTRADOR",
          access: true,
        },
        {
          role: "ASISTENTE MÉDICO",
          access: true,
        },
        {
          role: "DOCTOR",
          access: true,
        },
        {
          role: "UNAUTHORIZED",
          access: false,
          redirect: "login"
        }
      ]
    }
  },
]