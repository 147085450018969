export const treatmentsStore = {
  namespaced: true,
  state: {
    treatments:[],
    treatment:{},
  },
  mutations: {
    setTreatmentSelected (state, payload) {
      state.treatment = payload
    },
    setTreatments (state, payload) {
      state.treatments = payload
    },
  },
  actions: {
    /* eslint-disable */
    createdTreatment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('treatments', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateTreatment ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('update-prices', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    /* 0 porque en la vista esta declarado con ese valor (no problem) */
    getTreatments ({ commit }, id = 0) {
      return new Promise((resolve, reject) => {
        /* id es opcional cuando su valor es 0 */
        const url = id != 0 ? 'treatments/'+id : 'treatments'
        axios.get(url)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}