import Vue from 'vue'
import store from '@/store/'
import sidebar from './rules/sidebar.json'

const plugin = {
  install() {
    Vue.prototype.$can = (rule) => {
      if(store.state.auth.roleName === 'ADMINISTRADOR') return true

      return sidebar[store.state.auth.roleName].includes(rule)
    }
  }
}
Vue.use(plugin)

export default plugin;