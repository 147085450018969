<template>
  <v-dialog
    v-model="overlay"
    persistent
    max-width="300"
    content-class="box-shadow-none"
    overlay-color="#FFF"
  >
    <img class="image-loading pulsar" src="@/assets/logo.png" alt="">
  </v-dialog>
</template>

<script>
export default {
  name: 'common.dialog-loader',
  data: () => ({
    overlay: false,
  }),
  computed: {
    changeLoader() {
      return this.$store.state.loading
    },
  },
  watch: {
    changeLoader(val){
      if (val > 0) {
        this.overlay = true
      } else {
        this.overlay = false
      }
    },
  },
}
</script>

<style>
.box-shadow-none {
  box-shadow: none!important;
  overflow: hidden;
}

.image-loading {
  animation-name: parpadeo;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.7; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.7; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.7; }
  100% { opacity: 1.0; }
}

/* Animación con keyframe llamada "latidos" */
@keyframes latidos {
    from { transform: none; }
    50% { transform: scale(1.05); }
    to { transform: none; }
}
/* En la clase corazon vamos a llamar latidos en la animación  */
.pulsar {
	animation: latidos 1.5s infinite;
	transform-origin: center;
}

</style>