export const authStore = {
  namespaced: true,
  state: {
    authenticatedUser:{},
    selectedConsultory: '',
    roleName: 'UNAUTHORIZED'
  },
  mutations: {
    setAuthenticatedUser (state, payload) {
      state.authenticatedUser = payload
    },
    setSelectedConsultory (state, payload) {
      state.selectedConsultory = payload
    },
    setRoleName (state, payload) {
      state.roleName = payload
    }
  },
  actions: {
    /* eslint-disable */
    authenticateDomain({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        // primero verifica el dominio a una ruta distinta(la unica ruta)
        axios.defaults.baseURL = process.env.VUE_APP_APIAUTHURL
        
        axios.get('domain/' + payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            // al encontrar el dominio cambia de ruta a la de api que se usara para todas las consultas
            axios.defaults.baseURL = process.env.VUE_APP_APIURL
            /**
            ** Es necesario cambiar el dominio en cada login, porque al hacerlo
            ** en el archivo principal de configuracion de axios  NO CAMBIA
            */
            axios.defaults.headers.common['Domain'] = payload
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('login', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            commit('setAuthenticatedUser', res.data.data)
            commit('setRoleName', res.data.data.rol[0].name)
            localStorage.setItem('jwt', res.data.data.token)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    logout({ state, commit },token) {
      return new Promise((resolve, reject) => {
        axios.get('logout/'+ token)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            commit('setAuthenticatedUser', {})
            commit('setSelectedConsultory', '')
            commit('setRoleName', 'UNAUTHORIZED')
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })

        localStorage.removeItem('jwt')
        localStorage.removeItem('consultory')
        location.href = '/login'
      })
    },
    currentAuthenticatedUser({ commit, state }) {
      if (window.location.pathname === '/login') {
        return
      }

      if (!localStorage.getItem('domain')) {
        location.href = '/login'
        return
      }

      const token = localStorage.getItem('jwt')
      const consultory = localStorage.getItem('consultory')
      
      return new Promise((resolve, reject) => {
        /**
          ** Es necesario actualizar el dominio porque en el
          ** archivo principal no se puede(o no se como).
          */
        axios.defaults.headers.common['Domain'] = localStorage.getItem('domain').toLowerCase()

        axios.get('token/'+ token)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            commit('setAuthenticatedUser', res.data.data)
            commit('setRoleName', res.data.data.rol[0].name)

            localStorage.setItem('jwt', res.data.data.token)

            if (consultory) {
              commit('setSelectedConsultory', JSON.parse(consultory))
            } else {
              if (window.location.pathname != '/home') {
                location.href = '/home'
              }
            }
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
          localStorage.removeItem('jwt')
          localStorage.removeItem('consultory')
          location.href = '/login'
        })
      })
    }
  },
}