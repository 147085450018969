<template>
  <v-dialog transition="dialog-top-transition" max-width="550" v-model="dialog" persistent>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card outlined elevation="2"  class="rounded-lg">
        <v-card-title>
          <v-row class="d-flex align-center justify-space-between w-full px-4">
            <div></div><!-- important -->
            <p class="text-h5 primary--text pt-2">
              Crear nuevo tratamiento
            </p>
            <v-icon @click="$eventbus.$emit('close-modal-treatment')">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="mb-3">
          <v-row>
            <v-col cols="6">
              <label class="pb-0 mb-0">Nombre del tratamiento *</label>
              <v-text-field
                outlined
                dense
                hide-details="auto"
                class="rounded-lg"
                v-model.trim="treatment.name"
                :rules="[rules.required]"
                :error-messages="errors.consultories"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <label class="pb-0 mb-0">Precio del tratamiento</label>
              <v-text-field
                outlined
                dense
                :disabled="hasMaterialComputed"
                hide-details="auto"
                class="rounded-lg"
                v-model.trim="treatment.price"
                :rules="hasMaterials ? [] : [rules.required]"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <label class="pb-0 mb-0">A que consultorio(s) irá este tratamiento *</label>
              <v-select
                v-model="consultories"
                :items="allConsultories"
                item-text="name"
                item-value="id"
                dense
                outlined
                multiple
                attach
                chips
                hide-details="auto"
                placeholder="Seleccione"
                class="rounded-lg"
                :rules="[rules.requiredSelectMultiple]"
                :error-messages="errors.consultories"
                no-data-text="No hay datos que mostrar"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" small style="max-width: 100px;">
                    <span class="text-truncate">{{ item.name }}</span>
                  </v-chip>
                  <span
                    v-if="index === 1"
                    class="grey--text text-caption"
                  >
                    (+{{ consultories.length - 1 }} consultorios)
                  </span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="d-flex justify-space-between align-center py-0">
              <label class="text-gray-600 font-semibold text-lg d-block">¿Desea agregar materiales para este tratamiento?</label>
              <v-switch
                v-model="hasMaterials"
              ></v-switch>
            </v-col>
            <v-col cols="12"
              v-show="hasMaterials"
              v-for="(input, index) in materials"
              :key="`materialInput-${index}`"
            >
              <v-row>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    class="rounded-lg"
                    label="Nombre del material"
                    v-model.trim="input.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    outlined
                    dense
                    hide-details="auto"
                    class="rounded-lg"
                    label="Precio del material"
                    v-model.trim="input.price"
                    @keypress="onlyNumber"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="addField(input, materials)" icon color="primary" small>
                        <v-icon>
                          mdi-plus-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar mas campos</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" @click="removeField(index, materials)" icon color="error" small v-show="materials.length > 1">
                        <v-icon>
                          mdi-close-circle
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar campo</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-btn
            color="primary"
            class="mx-auto kiru-box-shadow-15"
            style="width: 170px;"
            small
            @click="createdTreatment()"
            >
            Crear Tratamiento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: 'modal.treatment-form',
  data () {
    return {
      dialog: true,
      valid: true,
      rules: {
        required: v => !!v || 'El campo es requerido',
        requiredSelectMultiple: v =>  (v && v.length > 0) || "El campo es requerido",
      },
      treatment: { name: "", price: 0 },
      materials: [{ name: "", price: 0 }],
      hasMaterials: false,
      consultories: null,
      allConsultories: [],
      errors: [],
      // slugIsoClickInButton: false
    }
  },
  computed: {
    hasMaterialComputed() {
      if (this.hasMaterials) {
        /* eslint-disable-next-line */
        this.treatment.price = '0'
        return true
      }
      return false
    }
  },
  created() {
    this.getConsultories()
  },
  methods:{
    createdTreatment(){
      this.errors = []
      if(this.$refs.form.validate()){
        let materialsData = []
        let hasMaterial = 'no'

        if (this.hasMaterials) {
          this.materials.forEach(material => {
            if (material.name.trim() != '') {
              if (material.price.trim() === '') {
                material.price = '0'
              }

              material.price = parseFloat(material.price)
              materialsData.push(material)
              hasMaterial = 'si'
            }
          })
        }

        let body = {
          'name': this.treatment.name.trim(),
          'price': parseFloat(this.treatment.price),
          'hasMaterial': hasMaterial,
          'consultories': JSON.stringify(this.consultories),
        }

        if (materialsData.length > 0) {
          body.material = JSON.stringify(materialsData)
        }

        const data = this.$formData(body)

        this.$store.dispatch('setting/createdTreatment', data).then((res) => {
          if (res.success) {
            this.$swal({
              icon: 'success',
              title: 'Exito',
              text: 'El tratamiento ha sido registrado exitosamente',
              confirmButtonColor: '#338880',
              confirmButtonText: 'Entendido'
            })

            this.treatment = { name: "", price: 0 }
            this.materials = [{ name: "", price: 0 }]
            this.consultories = null
            this.hasMaterials = false
            location.reload()
            this.$eventbus.$emit("close-modal-treatment")
          }

          if(res.message === 'Campos requeridos'){ this.errors = res.errors }
        })
        .catch(error => {
          console.error(error)
          this.$swal({
            icon: 'warning',
            title: 'Ops!',
            text: 'A sucedido un error por favor intentelo nuevamente',
            confirmButtonColor: '#ffb400',
            confirmButtonText: 'Entendido'
          })
        })
      }
    },
    getConsultories(){
      this.$store.dispatch('consultory/getConsultories').then((res) => {
        if(Object.keys(res.data).length > 0) {
          for (const data of res.data) {
            this.allConsultories.push({
              id: data.id,
              name: data.attributes.name
            })
          }
        }
      }).catch(error => {
        console.error(error)
      })
    },
    addField(obj, fieldType) {
      fieldType.push({ name: "", price: 0 });
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },
    onlyNumber ($event) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
      const keyPressed = $event.key;
      
      if (!keysAllowed.includes(keyPressed)) {
        $event.preventDefault()
      }
    },
  },
}
</script>