export const odontogramStore = {
  namespaced: true,
  state: {
    //odontogram
    toothIdentifier: '',
    showToothPreview: false,
    showOdontogramOptions: false,
    selectedTreatment: null,
    selectedMaterial: null,
    dataOfTheSelectedTreatment: {},
    dataOfTheSelectedMaterial: {},
    toothSelected: {},
    odontogramData: {
      specs: [],
      range: {},
      listOfTreatmentsNotVisible: []
    },
    rootOrCrownSelected: null,
    rules: {
      'aparato_ortodóntico_fijo_bueno': ['18', '28', '48', '38', '17', '27', '57', '37'],
      'aparato_ortodóntico_fijo_malo': ['18', '28', '48', '38', '17', '27', '57', '37'],
      'aparato_ortodóntico_removible_bueno': ['18', '28', '48', '38', '17', '27', '57', '37'],
      'aparato_ortodóntico_removible_malo': ['18', '28', '48', '38', '17', '27', '57', '37'],
      'implante': ['18', '28', '8', '48'],
    },
    listIdentify: [
      ['18', '17', '16', '15', '14', '13', '12', '11'], ['21', '22', '23', '24', '25', '26', '27', '28'],
                  ['55', '54', '53', '52', '51'], ['61', '62', '63', '64', '65'],
                  ['85', '84', '83', '82', '81'], ['71', '72', '73', '74', '75'],
      ['48', '47', '46', '45', '44', '43', '42', '41'], ['31', '32', '33', '34', '35', '36', '37', '38'],
    ],
    listOfOdontograms: []
  },
  mutations: {
    setToothIdentifier (state, payload) {
      state.toothIdentifier = payload
    },
    setShowToothPreview (state, payload) {
      state.showToothPreview = payload
    },
    setShowOdontogramOptions (state, payload) {
      state.showOdontogramOptions = payload
    },
    setSelectedTreatment (state, payload) {
      state.selectedTreatment = payload
    },
    setSelectedMaterial (state, payload) {
      state.selectedMaterial = payload
    },
    setDataOfTheSelectedTreatment (state, payload) {
      state.dataOfTheSelectedTreatment = payload
    },
    setDataOfTheSelectedMaterial (state, payload) {
      state.dataOfTheSelectedMaterial = payload
    },
    setToothSelected (state, payload) {
      if(state.toothSelected == payload.number) state.toothSelected = {}
      else state.toothSelected = payload
    },
    setOdontogramData (state, payload) {
      state.odontogramData = payload
    },
    setRootOrCrownSelected (state, payload) {
      state.rootOrCrownSelected = payload
    },
    setListOfOdontograms (state, payload) {
      state.listOfOdontograms = payload
    },
  },
  actions: {}
}