export const doctorsStore = {
  namespaced: true,
  state: {
    doctors:[],
    doctor:{},
  },
  mutations: {
    setDoctors (state, payload) {
      state.doctors = payload
    },
  },
  actions: {
    /* eslint-disable */
    getDoctors ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('doctor')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
            commit('setDoctors',res.data.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}