export const laboratoryStore = {
  namespaced: true,
  state: {
    laboratories:[],
    orders: []
  },
  mutations: {
    setLaboratories (state, payload) {
      state.laboratories = payload
    },
    setOrders (state, payload) {
      state.orders = payload
    }
  },
  actions: {
    /* eslint-disable */
    getLaboratories ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('laboratory')
        .then(res => {
          if (res.status === 200) {
            if (res.data.data.length > 0) {
              commit('setLaboratories',res.data.data) 
            }
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getOrders ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('orders')
        .then(res => {
          if (res.status === 200) {
            if (res.data.data.length > 0) {
              commit('setOrders', res.data.data) 
            }
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    createLaboratory ({ state, commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('laboratory',item)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateLaboratory ({ state, commit },[item,id]) {
      return new Promise((resolve, reject) => {
        axios.post('laboratory/'+id, item)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    createOrder ({ state, commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('order',item)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}