export const usersStore = {
  namespaced: true,
  /* eslint-disable */
  actions: {
    getUsers ({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios.get('user?page='+page)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    /* trae todos los usuarios sin paginacion y con estado activo */
    getUsersWithStateActive ({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios.get('get-user')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // busca a un usuario en el buscador del datatable
    searchUser ({ commit }, [text, page]) {
      return new Promise((resolve, reject) => {
        axios.get('find-user/'+text+'?page='+page)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUserById ({ commit }, idUser) {
      return new Promise((resolve, reject) => {
        axios.get('user/' + idUser)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    registerUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('user', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getImageUser ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.get('get-image-user/' + payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    updateUser ({ commit }, [payload, idUser]) {
      return new Promise((resolve, reject) => {
        axios.post('user/' + idUser, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    verifyConsultoryBeforeDeletingSchedule({ commit }, [idUser, payload]) {
      return new Promise((resolve, reject) => {
        axios.post('validate-consultory/' + idUser, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}