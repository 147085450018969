<template>
  <v-app class="font-nunito">
    <div :class="!$store.state.isTablet ? !mini ? 'msm' : 'msm2' : ''">
      <v-app-bar
        app clipped-left flat dark
        color="bg-blur"
        dense
        height="48"
      >
        <v-progress-linear
          :active="$store.state.loadingRouter"
          :indeterminate="$store.state.loadingRouter"
          absolute
          top
          color="primary"
        ></v-progress-linear>

        <v-icon v-if="$store.state.isTablet" class="primary--text" @click="drawer = !drawer">mdi-menu</v-icon>
        <div :class="`py-3 ${drawer ? 'pl-0': 'pl-5'}`">
          <v-img
            lazy-src="https://picsum.photos/id/11/10/6"
            max-width="80"
            contain
            src="@/assets/logo.png"
          ></v-img>
        </div>

        <v-breadcrumbs class="primary--text" :items="$store.state.breadcrumbs">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              style="font-size:12px"
              :disabled="item.disabled"
              :exact-path="true"
            >
              <strong>{{ item.text.toUpperCase() }}</strong>
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <v-spacer></v-spacer>
        <v-btn
          color="bg-blur"
          x-small
          fab
          dark
        >
          <v-icon class="grey--text">mdi-magnify</v-icon>
        </v-btn>
        <v-toolbar-title>
          <v-menu
            transition="slide-x-transition"
            right
            v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            :max-width="400"
             v-if="Object.keys($store.state.auth.authenticatedUser).length > 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                v-bind="attrs"
                v-on="on"
                color="bg-blur"
                class="mx-2"
              >
                <span class="primary--text">
                  ¡Hola,
                  <strong>
                    {{$store.state.auth.authenticatedUser.user[0].name}} {{$store.state.auth.authenticatedUser.user[0].last_name}}
                  </strong>
                  !
                </span>
              </v-chip>
              <v-list-item-avatar>
                <img
                  :src="$store.state.auth.authenticatedUser.user[0].url"
                  :alt="$store.state.auth.authenticatedUser.user[0].last_name" style="object-fit: cover;">
              </v-list-item-avatar>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img
                      :src="$store.state.auth.authenticatedUser.user[0].url"
                      :alt="$store.state.auth.authenticatedUser.user[0].last_name" style="object-fit: cover;">
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{$store.state.auth.authenticatedUser.user[0].name}}</v-list-item-title>
                    <v-list-item-subtitle>{{$store.state.auth.authenticatedUser.user[0].last_name}}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :class="fav ? 'red--text' : ''"
                      icon
                      @click="fav = !fav"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>

                </v-list-item>
                <v-divider></v-divider>
                <v-list-item class="ml-14">
                  <v-list-item-content>
                    <v-list-item-subtitle><strong>Correo:</strong> {{$store.state.auth.authenticatedUser.user[0].email}}</v-list-item-subtitle>
                    <v-list-item-subtitle><strong>Telèfono:</strong> {{$store.state.auth.authenticatedUser.user[0].phone_number}}</v-list-item-subtitle>
                    <v-list-item-subtitle><strong>Consultorio Actual:</strong> {{$store.state.auth.selectedConsultory.name}}</v-list-item-subtitle>
                    <v-btn
                      color="blue-grey darken-4"
                      class="white--text mt-3"
                      style="max-width: 200px;"
                      small
                      @click="logout()"
                    >
                      <v-icon>mdi-power-settings</v-icon> Cerrar Sesión
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  @click="menu = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </v-toolbar-title>
      </v-app-bar>
    </div>

    <v-navigation-drawer
      app clipped flat expand-on-hover
      v-model="drawer"
      :permanent="!$store.state.isTablet"
      :stateless="$store.state.isTablet ? false : true"
      :mini-variant.sync="mini"
      mini-variant-width="70"
      id="drawerMain"
      class="elevation-10"
      :class="$store.state.isTablet ? 'bg-sm-blur': 'bg-blur'"
    >
      <template v-slot:prepend>
        <div :class="`py-1 d-flex align-center ${!mini ? 'justify-space-between': 'justify-center'}`">
          <h3 v-if="!mini" class="pl-9 text-caption"><strong>GRUPO PROEFEX</strong></h3>
          <v-btn icon @click="mini = false; drawer = false" class="primary--text">
            <v-icon v-if="mini" style="transform: scaleX(-1);">mdi-menu</v-icon>
            <v-icon v-else>mdi-menu-open</v-icon>
          </v-btn>
          <!-- <v-btn icon @click="mini = !mini; drawer = !drawer" class="primary--text">
            <v-icon v-if="mini" style="transform: scaleX(-1);">mdi-menu</v-icon>
            <v-icon v-else>mdi-menu-open</v-icon>
          </v-btn> -->
        </div>
      </template>

      <v-divider></v-divider>

      <v-list dense nav>
        <v-list-item-group :active-class="links.home ? 'active-link-sidebar' : 'disable-link-sidebar'" class="mb-1">
          <v-list-item link :to="{name: 'home'}" @click="activeLink('home', true)">
            <v-list-item-icon class="py-2">
              <v-icon large class="">mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="py-2">Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-item-group :active-class="links.agenda ? 'active-link-sidebar' : 'disable-link-sidebar'" class="mb-1">
          <v-list-item v-if="$can('agenda')" link :to="{name: 'diary'}" @click="activeLink('agenda', true)">
            <v-list-item-icon class="py-2">
              <v-icon large class="">mdi-calendar-blank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="py-2">Agenda</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group
          v-model="linksGroup.administración"
          no-action
          :active-class="links.administración ? 'active-link-sidebar' : 'disable-link-sidebar'"
        >
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon large class="py-3">mdi-book</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="py-3">Administración</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-if="$can('usuarios')" link :to="{name: 'users'}" @click="activeLink('administración')">
            <v-list-item-content>
              <v-list-item-title>Usuarios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$can('consultorios')" link :to="{name: 'consultory'}" @click="activeLink('administración')">
            <v-list-item-content>
              <v-list-item-title>Consultorios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="$can('convenios')" link :to="{name: 'agreement'}" @click="activeLink('administración')">
            <v-list-item-content>
              <v-list-item-title>Convenios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link :to="{name: 'laboratory'}">
            <v-list-item-content>
              <v-list-item-title>Laboratorios</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item v-if="$can('kardex')" link :to="{name: 'kardex'}" @click="activeLink('administración')">
            <v-list-item-content>
              <v-list-item-title>Kardex</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item-group :active-class="links.paciente ? 'active-link-sidebar' : 'disable-link-sidebar'" class="mb-1">
          <v-list-item v-if="$can('paciente')" link :to="{name: 'patient'}" @click="activeLink('paciente', true)">
            <v-list-item-icon class="py-2">
              <v-icon large class="">mdi-calendar-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="py-2">Paciente</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
      </v-list-item-group>

        <!-- <v-list-group no-action active-class="active-link-sidebar">
          <template v-slot:activator>
            <v-list-item-icon class="v-list-item__icon--text">
              <v-icon large class="py-3">mdi-calendar-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="py-3">Paciente</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item link :to="{name: 'patient'}">
            <v-list-item-content>
              <v-list-item-title>Pacientes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Presupuestos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <!-- <v-list-group no-action active-class="active-link-sidebar">
          <template v-slot:activator>
            <v-list-item-icon class="v-list-item__icon--text">
              <v-icon large class="py-2" style="transform: scaleX(-1);">mdi-cash-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="py-3">Caja</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Ventas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Egresos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Presupuestos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <!-- <v-list-group no-action active-class="active-link-sidebar">
          <template v-slot:activator>
            <v-list-item-icon class="v-list-item__icon--text">
              <v-icon large class="py-3" style="transform: scaleX(-1);">mdi-poll</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="py-3">Reportes</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Reporte Ventas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Reporte Atenciones</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <v-list-group
          v-if="$can('configuraciones')"
          v-model="linksGroup.configuración"
          no-action
          :active-class="links.configuración ? 'active-link-sidebar' : 'disable-link-sidebar'"
        >
          <template v-slot:activator>
            <v-list-item-icon class="v-list-item__icon--text">
              <v-icon large class="py-3" style="transform: rotate(180deg);">mdi-cogs</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title class="py-3">Configuración y Soporte</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Soporte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Ticket ayuda</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- :to="{name: 'setting.treatment'}" -->
          <!-- es necesario el href para que cambie de ruta porque hay conflico cuando ve los precios de un consultorio
          y luego quiere ver la configuracion de tratamientos, pues no cambia el contenido -->
          <!-- href="/setting/treatments"  -->
          <v-list-item link :to="{name: 'setting.treatment'}" @click="activeLink('configuración')">
            <v-list-item-content>
              <v-list-item-title>Configuración de tratamientos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="newTreatment()">
            <v-list-item-content>
              <v-list-item-title>Crear Nuevo Tratamiento</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link>
            <v-list-item-content>
              <v-list-item-title>Servicios / Precios</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
      </v-list>

      <template v-slot:append>
        <v-list dense nav>
          <v-list-item-group>
            <v-list-item link>
              <v-list-item-icon>
                <v-icon large class="">mdi-power-settings</v-icon>
              </v-list-item-icon>
              <v-list-item-title @click="logout()"><h5>Cerrar Sesión</h5></v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main class="accent pt-14">
      <v-layout>
        <v-container style="height: auto;min-height: 100vh; align-items: start;">
          <router-view/>
        </v-container>
      </v-layout>

      <v-footer padless>
        <v-col
          class="text-center"
          cols="12"
          style="font-size: 14px;"
        >
          Copyright &copy; 2022 | Desarrollado por el — <a href="https://proefexperu.com/" target="_blank" style="color: black;text-decoration: none;"><strong>Grupo Proefex</strong></a>
        </v-col>
      </v-footer>
    </v-main>

    <Loader/>

    <ModalTreatment v-if="showTreatmentModalComponent"/>

    <v-snackbar
      right
      top
      :color="$store.state.snackbar.color"
      :timeout="10000"
      v-model="$store.state.snackbar.showSnackbar"
    >
      {{ $store.state.snackbar.msg }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="$store.state.snackbar.colorTextClose"
          text
          v-bind="attrs"
          @click="$store.commit('setShowSnackbar', {showSnackbar: false})"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import Loader from '@/components/common/Loader.vue'
import ModalTreatment from '@/components/modals/modalTreatment.vue'

export default {
  name: 'layout-view',
  components: {
    Loader,
    ModalTreatment
  },
  data() {
    return {
      menu: '',
      fav: '',
      drawer: false,
      mini: true,
      links: {
        home: true,
        agenda: true,
        administración: true,
        paciente: true,
        configuración: true,
      },
      linksGroup: {
        administración: false,
        configuración: false,
      },
      showTreatmentModalComponent: false
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    
    /*
    *****************************
    CODIGO INMANTENIBLE(I'm exaggerating)
    (para mi yo del futuro)
    *****************************
    (Razon) el componente de navidation-drawer me tiene loco(como activaciones de links, diseño en responsive, etc)
    y en algunas otras cosas que no [entiendo o tengo poco conocimiento].

    Funciona pero con algunos hacks que por el momento halle de solución.
    *****************************
    (Lo siento) para mi yo del futuro jajaja
    *****************************
    */
  },
  methods: {
    activeLink(link, linksGroup = false){
      /* cuando se da click en un link que no tenga subitems, es necesario cerrar los demas links(en caso esten abiertos) que tienen subitems */
      if(linksGroup) { for (const key in this.linksGroup) {this.linksGroup[key] = false} }

      for (const key in this.links) {
        if (key === link) { this.links[key] = true }
        else { this.links[key] = false }
      }
    },
    logout() {
      if (localStorage.getItem('jwt')) {
        const token = localStorage.getItem('jwt')
        this.$store.dispatch('auth/logout', token).then(() => {})
      } else {
        location.href = '/login'
      }
    },
    handleResize () {
      // si la resolucion de la pantalla es menor a 960 ocultara el menu lateral
      if (window.innerWidth < 960) {
        this.$store.commit('setIsTablet', true)
      } else {
        this.$store.commit('setIsTablet', false)
      }
    },
    newTreatment() {
      this.showTreatmentModalComponent = true
    }
  },
  mounted() {
    this.$eventbus.$on("close-modal-treatment", () => { this.showTreatmentModalComponent = false })
  },
  destroyed() {
    this.$eventbus.$off('close-modal-treatment')
  },
};
</script>

<style lang="scss">
.v-breadcrumbs .v-breadcrumbs__item {
  color: #338880!important;
}
.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38)!important;
}
.v-breadcrumbs .v-breadcrumbs__divider {
  color: rgba(0, 0, 0, 0.38)!important;
}
.v-breadcrumbs li:nth-child(even) {
  padding: 0 6px!important;
}
.bg-blur {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.6)!important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2)!important;
  backdrop-filter: blur(14px)!important;
  -webkit-backdrop-filter: blur(14px);
}
.bg-sm-blur {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.8)!important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2)!important;
  backdrop-filter: blur(14px)!important;
  -webkit-backdrop-filter: blur(14px);
}

.swal2-container {
  font-family: 'Nunito';
}
.msm {
  transition: all 300ms;
  margin-left: 250px;
}
.msm2 {
  transition: all 300ms;
  margin-left: 70px;
}
.msm3 {
  margin-left: 0;
}
.active-link-sidebar {
  background: #338880;
  color: #FFF !important;
}
.disable-link-sidebar {
  color: rgba(0, 0, 0, 0.54) !important;
}
.disable-link-text-sidebar {
  color: rgba(0, 0, 0, 0.80);
}
#drawerMain > .v-navigation-drawer__content > .v-list > .v-item-group .v-list-item:hover,
#drawerMain > .v-navigation-drawer__content > .v-list > .v-list-group > .v-list-group__header:hover,
#drawerMain > .v-navigation-drawer__append > .v-list > .v-item-group > .v-list-item:hover{
  background: #338880;
  border-radius: 5px;
  color: #FFF !important;
}
#drawerMain > .v-navigation-drawer__content > .v-list > .v-item-group > .v-list-item:hover > .v-list-item__icon > .v-icon,
#drawerMain > .v-navigation-drawer__content > .v-list > .v-list-group > .v-list-group__header:hover > .v-list-item__icon > .v-icon,
#drawerMain > .v-navigation-drawer__append > .v-list > .v-item-group > .v-list-item:hover > .v-list-item__icon > .v-icon {
  color: #FFF !important;
}
// #drawerMain > .v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon {
//   margin-left: 18px;
// }
.theme--light.v-list-item--active .v-list-item__subtitle, .theme--light.v-list-item .v-list-item__action-text {
  color: #FFF !important;
}
.application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}
.v-list-item--dense .v-list-item__icon, .v-list--dense .v-list-item .v-list-item__icon {
  height: 24px;
  margin-top: 15px!important;
  margin-bottom: 15px!important;
}
</style>