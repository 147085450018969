export const AgreementsRoutes = [
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "Agreement Index" */ '@/views/agreement/index.vue'),
    redirect: {name: 'agreement.list'},
    children: [
      {
        path: '/',
        name: 'agreement.list',
        component: () => import(/* webpackChunkName: "Agreement List" */ '@/views/agreement/Agreement.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      },
      {
        path: ':id/discount-treatment',
        name: 'agreement.discount',
        component: () => import(/* webpackChunkName: "Agreement Discount Treatments" */ '@/views/agreement/DiscountTreatments.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: true,
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      }
    ]
  },
]