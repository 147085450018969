<template>
  <v-app>
    <main class="auth">
      <div class="auth__banner container--pall">
        <div class="wrap__banner">
          <h1 class="wrap__banner--title animation-from-left-to-right font-nunito">
            ¡Bienvenido a Kiru!
          </h1>
          <p class="wrap__banner--context animation-from-left-to-right font-nunito">
            <strong>KIRU</strong> es un software creado para ayudar a facilitar y gestionar mejor el trabajo de los odontólogos  en consultorios particulares, clínicas y centros odontológicos.<br /><br/>
            Por favor ingrese sus <strong>credenciales </strong>para dar el siguiente paso.
          </p>
          <a href="https://kirudent.com/" target="_blank" class="button button--go-web font-nunito">Ir a la Web</a>
          <Networks class="wrap__banner--networks animation-from-left-to-right"/>
        </div>
      </div>
      <div class="auth__form">
        <v-stepper class="stepper__form" v-model="stepper">
          <v-stepper-header class="stepper__header">
            <v-stepper-step step="1" :complete="stepper > 1" @click="stepper = 1; stepOneCompleted = false"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2" :complete="stepper > 2"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items class="stepper__items">
            <v-stepper-content step="1">
              <v-form ref="firstValidForm" v-model="firstValidForm" lazy-validation class="d-flex align-center flex-column py-8">
                <p class="tagline font-weight-bold text--primary mb-10">Ingrese su dominio</p>
                <v-text-field
                  label="Dominio"
                  class="field mb-16"
                  v-model.trim="domain"
                  :rules="[rules.required, rules.minDomain]"
                  :error-messages="errorMsg"
                  aria-autocomplete="off"
                  hint="Al menos 3 carácteres"
                  counter
                  required
                ></v-text-field>
                <v-btn class="btn-login mb-6 px-10" @click="autenticated()">Siguiente</v-btn>
                <!----<a href="https://kirudent.com/help-center" target="_blank" class="grey--text mb-3">
                  <small>Olvide mi dominio</small>
                </a> -->
              </v-form>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-form ref="secondValidForm" v-model="secondValidForm" lazy-validation class="d-flex align-center flex-column py-8">
                <p class="tagline font-weight-bold text--primary mb-10">Ingrese sus credenciales</p>
                <v-text-field
                  label="Correo electronico"
                  type="email"
                  required
                  class="field"
                  v-model.trim="email"
                  :rules="[rules.required, rules.singleEmail]"
                  ></v-text-field>
                <v-text-field
                  v-model.trim="password"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.minPassword]"
                  :type="showPassword ? 'text' : 'password'"
                  label="Contraseña"
                  class="field pb-8"
                  hint="Al menos 8 carácteres"
                  counter
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-btn class="btn-login mb-6 px-10" @click="login()">Ingresar</v-btn>
                <!----<a href="https://kirudent.com/help-center" target="_blank" class="grey--text mb-3">
                  <small>Olvide mi contraseña</small>
                </a>
                  -->
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </div>

      <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Por favor espere
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </main>
  </v-app>
</template>

<script>
  import Networks from '@/components/common/Networks.vue'

  export default {
    name: 'auth-page',
    components: { Networks },
    data: () => ({
      profile:'',
      domain: '',
      email: '',
      password: '',
      errorMsg: '',
      stepper: 1,
      loading: false,
      showPassword: false,
      firstValidForm: true,
      secondValidForm: true,
      stepOneCompleted: false,
      rules: {
        required: v => !!v || 'El campo es requerido',
        singleEmail: v => /.+@.+\..+/.test(v) || 'El formato del email es invalido',
        minPassword: v => v.length >= 8 || 'Este campo debe debe tener 8 o mas caracteres',
        minDomain: v => v.length >= 3 || 'Este campo debe debe tener 3 o mas caracteres',
      },
    }),
    beforeCreate() {
      localStorage.removeItem('jwt')
      localStorage.removeItem('consultory')
    },
    created() {
      if(localStorage.getItem('domain')) {
        this.domain = localStorage.getItem('domain').toLowerCase()
      }
    },
    mounted(){
      const searchParams = new URLSearchParams(window.location.search);
        if(searchParams.has('profile')){
          this.domain = searchParams.get('profile');
        }   
    },
    methods: {
      autenticated() {
        if (this.$refs.firstValidForm.validate()) {
          this.loading = true
          this.errorMsg = ''

          this.$store.dispatch('auth/authenticateDomain', this.domain.toLowerCase()).then(res => {
            if(res.data) {
              this.stepper = 2
              this.stepOneCompleted = true
            } else {
              this.errorMsg = 'Dominio no encontrado o con tiempo expirado'
              this.stepper = 1
            }
          }).catch(error => {
            console.error(error)
            this.$swal({
              icon: 'error',
              title: 'Error',
              text: 'A sucedido un error por favor intentelo nuevamente',
              confirmButtonColor: '#ff4c51',
              confirmButtonText: 'Entendido',
              allowOutsideClick: false,
            }).then(() => {
              location.reload()
            })
            this.stepper = 1
          }).finally(() => {
            this.loading = false
          })
        }
      },
      login() {
        if(!this.stepOneCompleted || this.domain.trim() === '' || !isNaN(this.domain.trim())) {
          this.$swal({
            icon: 'info',
            title: 'Ops!',
            html: `Tu usuario es incorrecto, por favor <strong>revisa tus credenciales</strong>`,
            confirmButtonColor: '#16b1ff',
            confirmButtonText: 'Entendido',
            allowOutsideClick: false,
          }).then(() => {
            location.reload()
            this.stepper = 1
          })

          return
        }

        if(this.$refs.secondValidForm.validate()){
          this.loading = true

          this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password
          }).then((res) => {
            if(res.success) {
              this.stepper = 3
              localStorage.setItem('domain', this.domain.toLowerCase())
              location.href = '/home'
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: 'A sucedido un error por favor intentelo nuevamente',
                confirmButtonColor: '#ff4c51',
                confirmButtonText: 'Entendido',
                allowOutsideClick: false,
              }).then(() => {
                location.reload()
                this.stepper = 1
              })
            }
          }).catch(error => {
            if (error.response.data.data.user === 'credenciales incorrectas') {
              this.$swal({
                icon: 'info',
                title: 'Ops!',
                html: `Tus credenciales son incorrectas, por favor <strong>revisa tus credenciales</strong>`,
                confirmButtonColor: '#16b1ff',
                confirmButtonText: 'Entendido'
              })
              return
            }

            this.$swal({
              icon: 'error',
              title: 'Error',
              text: 'A sucedido un error por favor intentelo nuevamente',
              confirmButtonColor: '#ff4c51',
              confirmButtonText: 'Entendido'
            })

          }).finally(() => {
            this.loading = false
          })
        }
      }


    }
  }
</script>

<style lang="scss">
.stepper__form {
  display: flex;
  box-shadow: none!important;
  overflow: visible!important;
}

.stepper__header {
  max-width: 200px;
  justify-content: space-evenly!important;
  box-shadow: none!important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.stepper__items {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 10px;
  box-shadow: 0 15px 50px rgba(0,0,0,0.07);
}

.tagline {
  font-size: 22px!important;
  font-weight: 800!important;
  letter-spacing: -.5px;
  background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.auth {
  height: 100vh;
  position: relative;

  @include breakpoint-up(large) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  &__banner {
    display: flex;
    align-items: flex-start;
    height: 100vh;
    padding-top: 35px;
    padding-right: 24px;
    padding-bottom: 35px;
    padding-left: 24px;
    text-align: center;
    justify-content: center;

      @include breakpoint-up(large) {
      text-align: left;
      background: var(--secondary);
      padding-top: 96px;
      padding-bottom: 96px;
      justify-content: start;
      align-items: center;
      height: auto;
    }

    .wrap__banner {
      &--title {
        font-weight: 900;
        font-size: 30px;
        color: var(--secondary);
        
        @include breakpoint-up(large) {
          color: var(--white);
          font-size: 50px;
        }
      }

      &--context {
        max-width: 500px;
        font-size: 16px;
        color: var(--grayishBlue);
        line-height: 1.5;
        margin-bottom: 36px;

        @include breakpoint-up(large) {
          color: var(--white);
        }
      }

      .button--go-web {
        text-decoration: none;
        padding-top: 6px;
        padding-bottom: 6px;
        color: var(--primary);
        background: transparent;
        border: 2px solid var(--primary);
        margin-bottom: 25px;
        font-size: 14px;
        box-shadow: none;
        
        @include breakpoint-up(large) {
          color: var(--white);
          border: 2px solid var(--white);
        }
      }

      &--networks {
        visibility: hidden;

        @include breakpoint-up(large) {
          visibility: visible;
        }
      }
    }
  }

  &__form {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;

    @include breakpoint-up(large) {
      position: relative;
      top: 0;
    }

    .stepper__form {
      position: relative;
      height: 450px;
      width: 300px;
      display: flex;
      flex-direction: column;
      border-radius: 10px;

      @include breakpoint-up(large) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        height: 530px;
        width: 348px;
      }
    }
  }
}

@keyframes move-circle {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.button {
  padding: 12px 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: var(--white);
  box-shadow: 0 15px 40px hsl(170, 100%, 40%, 50%);
  border-radius: 30px;
  display: inline-block;
  // font-family: var(--font-poppins);
  font-size: var(--font-sm);
  position: relative;
  background-image: linear-gradient(to right, #02AAB0 0%, #00CDAC  51%, #02AAB0  100%);
  cursor: pointer;
  letter-spacing: 1px;
  border: none;
}

.button:hover {
  background-position: right center; /* change the direction of the change here */
  color: var(--white);
  text-decoration: none;
}
</style>