import { images } from '@/views/patient/odontogram/images'

const theTreatmentIsType = (treatment) => {
  const treatments = {
    aparato_ortodóntico_fijo_bueno: 'range',//1
    aparato_ortodóntico_fijo_malo: 'range',//2
    caries: 'parts',//3
    aparato_ortodóntico_removible_bueno: 'range',//4
    aparato_ortodóntico_removible_malo: 'range',//5
    desgaste_oclusal: 'square',//6 
    corona_definitiva: 'unique_and_square',//7
    corona_temporal: 'unique_and_square',//8
    diente_discrómico: 'square',//9
    diastema: 'unique',//10
    diente_ausente: 'unique',//11
    diente_extraído: 'unique',//12
    diente_en_clavija: 'unique',//13
    diente_extruido: 'unique',//14
    diente_intruido: 'unique',//15
    edéntulo_total: 'range',//16
    fractura: 'fracture',//17
    giroversión_izquierda: 'unique',//18
    impactación: 'square',//19
    implante: 'square',//20
    giroversión: 'unique',//21
    macrodoncia: 'square',//22
    microdoncia: 'square',//23
    migración: 'unique',//24
    migración_izquierda: 'unique',//25
    movilidad: 'square',//26
    prótesis_fija_buena: 'range',//27
    prótesis_fija_malo: 'range',//28
    prótesis_total_buena: 'range',//29
    prótesis_total_malo: 'range',//30
    remanente_radicular: 'unique',//31
    prótesis_removible_bueno: 'range',//32
    prótesis_removible_malo: 'range',//33
    restauración: 'parts_and_square',//34
    restauración_temporal: 'parts_and_square',//35
    supernumerario: 'unique',//36
    transposición: 'unique',//37
    tratamiento_pulpar: 'unique_and_square',//38
    tratamiento_pulpar_malo: 'unique_and_square',//39
    geminación_fusión: 'unique',//40
    perno_bueno: 'unique_and_square',//41
    perno_malo: 'unique_and_square',//42
    sellante_bueno: 'unique',//43
    sellante_malo: 'unique',//44
  }

  return treatments[treatment]
}

const getTheElementsOfTheSquares = (odontogram) => {
  const treatmentsTypeSquare = [
    'desgaste_oclusal',
    'diente_discrómico',
    'impactación',
    'implante',
    'macrodoncia',
    'microdoncia',
    'movilidad'
  ]
  let squareValues = []

  /** 
    * * juntamos todos los valores de los tratamientos en un solo array
    * * para verfificar si ya existe un square vacio o no
    */
  Object.keys(odontogram).forEach((data) => { // 18, 19...
    Object.keys(odontogram[data]).forEach((treatment) => { // caries, diastesma...
      if (treatmentsTypeSquare.includes(treatment)) {
        Object.keys(odontogram[data][treatment]).forEach((element) => { // square-18-2, square-18-3...
          squareValues = squareValues.concat(element)
        })
      }
    })
  })

  Object.keys(odontogram).forEach((data) => {
    Object.keys(odontogram[data]).forEach((treatment) => {
      if (odontogram[data][treatment].materials) {
        Object.keys(odontogram[data][treatment].materials).forEach((material) => {
          squareValues = squareValues.concat(Object.keys(odontogram[data][treatment].materials[material]))
        })
      }
    })
  })

  return squareValues
}

const treatments = {
  sellante_bueno: {
    image: images.sellante_bueno,
    wholeTooth: true,
  },
  sellante_malo: {
    image: images.sellante_malo,
    wholeTooth: true,
  },
  perno_bueno: {
    image: images.perno_bueno,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      perno_bueno_mf: {
        image: images.perno_bueno_mf,
        wholeTooth: true,
      },
      perno_bueno_mm: {
        image: images.perno_bueno_mm,
        wholeTooth: true,
      },
    },
  },
  perno_malo: {
    image: images.perno_malo,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      perno_mf: {
        image: images.perno_mf,
        wholeTooth: true,
      },
      perno_mm: {
        image: images.perno_mm,
        wholeTooth: true,
      },
    },
  },
  aparato_ortodóntico_fijo_bueno: {
    start: images.ordontico_fijo_bueno,
    image: images.ordontico_fijo_bueno2,
    end: images.ordontico_fijo_bueno3,
    range: true,
  },
  aparato_ortodóntico_fijo_malo: {
    start: images.ordontico_fijo_malo,
    image: images.ordontico_fijo_malo2,
    end: images.ordontico_fijo_malo3,
    range: true,
  },
  aparato_ortodóntico_removible_bueno: {
    image: images.aparato_ortodóntico_removible_bueno,
    start: images.aparato_ortodóntico_removible_bueno,
    end: images.aparato_ortodóntico_removible_bueno,
    range: true,
  },
  aparato_ortodóntico_removible_malo: {
    image: images.aparato_ortodóntico_removible_malo,
    start: images.aparato_ortodóntico_removible_malo,
    end: images.aparato_ortodóntico_removible_malo,
    range: true,
  },
  caries: {
    onlySections: true,
    image: images.caries,
  },
  corona_definitiva: {
    image: images.corona_definitiva,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      corona_definitiva_cc: {
        image: images.corona_definitiva_cc,
        wholeTooth: true,
      },
      corona_definitiva_cf: {
        image: images.corona_definitiva_cf,
        wholeTooth: true,
      },
      corona_definitiva_cj: {
        image: images.corona_definitiva_cj,
        wholeTooth: true,
      },
      corona_definitiva_cmc: {
        image: images.corona_definitiva_cmc,
        wholeTooth: true,
      },
      corona_definitiva_cv: {
        image: images.corona_definitiva_cv,
        wholeTooth: true,
      },
    },
  },
  corona_temporal: {
    image: images.corona_temporal,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      corona_temporal_cc: {
        image: images.corona_temporal_cc,
        wholeTooth: true,
      },
      corona_temporal_cf: {
        image: images.corona_temporal_cf,
        wholeTooth: true,
      },
      corona_temporal_cj: {
        image: images.corona_temporal_cj,
        wholeTooth: true,
      },
      corona_temporal_cmc: {
        image: images.corona_temporal_cmc,
        wholeTooth: true,
      },
      corona_temporal_cv: {
        image: images.corona_temporal_cv,
        wholeTooth: true,
      },
    },
  },
  desgaste_oclusal: {
    image: images.desgaste_oclusal,
    superior_block: true,
    wholeTooth: true,
  },
  diastema: {
    image: images.diastema,
    //betweenTooth: true,
    wholeTooth: true,
    disableOnLastItems: true,
  },
  diente_ausente: {
    image: images.diente_ausente,
    wholeTooth: true,
  },
  diente_extraído: {
    image: images.diente_extraído,
    wholeTooth: true,
  },

  diente_discrómico: {
    image: images.diente_discrómico,
    superior_block: true,
    wholeTooth: true,
  },
  diente_ectópico: {
    image: images.diente_ectópico,
    superior_block: true,
    wholeTooth: true,
  },
  diente_en_clavija: {
    image: images.diente_en_clavija,
    wholeTooth: true,
  },
  diente_extruido: {
    image: images.diente_extruido,
    wholeTooth: true,
  },
  diente_intruido: {
    image: images.diente_intruido,
    wholeTooth: true,
  },
  edéntulo_total: {
    image: images.edéntulo_total,
    end: images.edéntulo_total,
    start: images.edéntulo_total,
    range: true,
  },
  fractura: {
    image: images.fractura,
    onlyCrownOrRoot: true,
  },
  geminación_fusión: {
    image: images.geminación_fusión,
    //rangeNextOrPreviousOnly: true
    wholeTooth: true,
    disableOnLastItems: true,
  },
  giroversión: {
    image: images.giroversión,
    wholeTooth: true,
  },
  impactación: {
    image: images.impactación,
    superior_block: true,
    wholeTooth: true,
  },
  implante: {
    image: images.implante,
    superior_block: true,
    wholeTooth: true,
  },
  macrodoncia: {
    image: images.macrodoncia,
    superior_block: true,
    wholeTooth: true,
  },
  microdoncia: {
    image: images.microdoncia,
    superior_block: true,
    wholeTooth: true,
  },
  migración: {
    image: images.migración,
    wholeTooth: true,
  },
  movilidad: {
    image: images.movilidad,
    superior_block: true,
    wholeTooth: true,
    /* materials: {
                      restauración_am: {
                          image: images.restauración_am,
                          superior_block: true,
                          onlySections: true
                      },
                      restauración_ie: {
                          image: images.restauración_ie,
                          superior_block: true,
                          onlySections: true
                      },
                      restauración_im: {
                          image: images.restauración_im,
                          superior_block: true,
                          onlySections: true
                      },
                      restauración_iv: {
                          image: images.restauración_iv,
                          superior_block: true,
                          onlySections: true
                      },
                      restauración_r: {
                          image: images.restauración_r,
                          superior_block: true,
                          onlySections: true
                      },
                  } */
  },
  prótesis_fija_buena: {
    start: images.prótesis_fija_buena,
    image: images.prótesis_fija_buena2,
    end: images.prótesis_fija_buena3,
    range: true,
  },
  prótesis_fija_malo: {
    start: images.prótesis_fija_malo,
    image: images.prótesis_fija_malo2,
    end: images.prótesis_fija_malo3,
    range: true,
  },

  prótesis_removible_bueno: {
    image: images.prótesis_removible_bueno,
    start: images.prótesis_removible_bueno,
    end: images.prótesis_removible_bueno,
    range: true,
  },

  prótesis_removible_malo: {
    image: images.prótesis_removible_malo,
    start: images.prótesis_removible_malo,
    end: images.prótesis_removible_malo,
    range: true,
  },

  prótesis_total_buena: {
    image: images.prótesis_total_buena,
    start: images.prótesis_total_buena,
    end: images.prótesis_total_buena,
    range: true,
  },
  prótesis_total_malo: {
    image: images.prótesis_total_malo,
    start: images.prótesis_total_malo,
    end: images.prótesis_total_malo,
    range: true,
  },
  remanente_radicular: {
    image: images.remanente_radicular,
    wholeTooth: true,
  },
  restauración: {
    image: images.restauración,
    superior_block_option: true,
    superior_block: true,
    onlySections: true,
    materials: {
      restauración_am: {
        image: images.restauración_am,
        superior_block: true,
        onlySections: true,
      },
      restauración_ie: {
        image: images.restauración_ie,
        superior_block: true,
        onlySections: true,
      },
      restauración_im: {
        image: images.restauración_im,
        superior_block: true,
        onlySections: true,
      },
      restauración_iv: {
        image: images.restauración_iv,
        superior_block: true,
        onlySections: true,
      },
      restauración_r: {
        image: images.restauración_r,
        superior_block: true,
        onlySections: true,
      },
    },
  },
  restauración_temporal: {
    image: images.restauración_temporal,
    superior_block_option: true,
    superior_block: true,
    onlySections: true,
    materials: {
      restauración_am_r: {
        image: images.restauración_am_r,
        superior_block: true,
        onlySections: true,
      },
      restauración_ie_r: {
        image: images.restauración_ie_r,
        superior_block: true,
        onlySections: true,
      },
      restauración_im_r: {
        image: images.restauración_im_r,
        superior_block: true,
        onlySections: true,
      },
      restauración_iv_r: {
        image: images.restauración_iv_r,
        superior_block: true,
        onlySections: true,
      },
      restauración_r_r: {
        image: images.restauración_r_r,
        superior_block: true,
        onlySections: true,
      },
    },
  },
  semi_impactación: {
    image: images.semi_impactación,
    superior_block: true,
    wholeTooth: true,
  },
  supernumerario: {
    image: images.supernumerario,
    wholeTooth: true,
    disableOnLastItems: true,
    //betweenTooth: true,
  },
  transposición: {
    image: images.transposición,
    wholeTooth: true,
    //rangeNextOrPreviousOnly: true,
  },
  tratamiento_pulpar_malo: {
    image: images.tratamiento_pulpar_malo,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      tratamiento_pulpar_pc_r: {
        image: images.tratamiento_pulpar_pc_r,
        text: 'Tratamiento Pulpar PC',
      },
      tratamiento_pulpar_pp_r: {
        image: images.tratamiento_pulpar_pp_r,
        text: 'Tratamiento Pulpar PP',
      },
      tratamiento_pulpar_tc_r: {
        image: images.tratamiento_pulpar_tc_r,
        text: 'Tratamiento Pulpar TC',
      },
    },
  },
  tratamiento_pulpar: {
    image: images.tratamiento_pulpar,
    wholeTooth: true,
    superior_block_option: true,
    disableHide: true,
    materials: {
      tratamiento_pulpar_pc: {
        image: images.tratamiento_pulpar_pc,
        text: 'Tratamiento Pulpar PC',
      },
      tratamiento_pulpar_pp: {
        image: images.tratamiento_pulpar_pp,
        text: 'Tratamiento Pulpar PP',
      },
      tratamiento_pulpar_tc: {
        image: images.tratamiento_pulpar_tc,
        text: 'Tratamiento Pulpar TC',
      },
    },
  },

  /// delete soon
  diente_extruido_arriba: {
    image: images.diente_extruido_arriba,
    wholeTooth: true,
  },
  diente_intruido_arriba: {
    image: images.diente_intruido_arriba,
    wholeTooth: true,
  },
  transposición_arriba: {
    image: images.transposición_arriba,
    wholeTooth: true,
  },
  giroversión_izquierda: {
    image: images.giroversión_izquierda,
    wholeTooth: true,
  },
  migración_izquierda: {
    image: images.migración_izquierda,
    wholeTooth: true,
  },
}

const listOfTreatments = [
  {name: 'Aparato ortodóntico fijo', label: 'aparato_ortodóntico_fijo_bueno', img: images.aparato_ortodóntico_fijo_bueno},
  {name: 'Aparato ortodóntico fijo', label: 'aparato_ortodóntico_fijo_malo', img: images.aparato_ortodóntico_fijo_malo},
  {name: 'Caries', label: 'caries', img: images.caries },
  {name: 'Aparato ortodóntico removible', label: 'aparato_ortodóntico_removible_bueno', img: images.aparato_ortodóntico_removible_bueno},
  {name: 'Aparato ortodóntico removible', label: 'aparato_ortodóntico_removible_malo', img: images.aparato_ortodóntico_removible_malo},
  {name: 'Desgaste oclusal', label: 'desgaste_oclusal', img: images.desgaste_oclusal },
  {name: 'Corona definitiva', label: 'corona_definitiva', img: images.corona_definitiva },
  {name: 'Corono temporal', label: 'corona_temporal', img: images.corona_temporal },
  {name: 'Diente discromico', label: 'diente_discrómico', img: images.diente_discrómico },
  {name: 'Diastema', label: 'diastema', img: images.diastema },
  {name: 'Diente ausente', label: 'diente_ausente', img: images.diente_ausente },
  {name: 'Diente extraído', label: 'diente_extraído', img: images.diente_extraído },
  {name: 'Diente en clavija', label: 'diente_en_clavija', img: images.diente_en_clavija },
  {name: 'Diente extruido', label: 'diente_extruido', img: images.diente_extruido },
  {name: 'Diente intruido', label: 'diente_intruido', img: images.diente_intruido },
  {name: 'Edentulo total', label: 'edéntulo_total', img: images.edéntulo_total },
  {name: 'Fractura', label: 'fractura', img: images.fractura },
  {name: 'Giroversión izquierda', label: 'giroversión_izquierda', img: images.giroversión_izquierda },
  {name: 'Impactación', label: 'impactación', img: images.impactación },
  {name: 'Implante', label: 'implante', img: images.implante},
  {name: 'Giroversión derecha', label: 'giroversión', img: images.giroversión },
  {name: 'Macrodoncia', label: 'macrodoncia', img: images.macrodoncia },
  {name: 'Microdoncia', label: 'microdoncia', img: images.microdoncia },
  {name: 'Migración derecha', label: 'migración', img: images.migración },
  {name: 'Migración izquierda', label: 'migración_izquierda', img: images.migración_izquierda },
  {name: 'Movilidad', label: 'movilidad', img: images.movilidad },
  {name: 'Prótesis fija', label: 'prótesis_fija_buena', img: images.prótesis_fija_buena },
  {name: 'Prótesis fija', label: 'prótesis_fija_malo', img: images.prótesis_fija_malo },
  {name: 'Prótesis total', label: 'prótesis_total_buena', img: images.prótesis_total_buena },
  {name: 'Prótesis total', label: 'prótesis_total_malo', img: images.prótesis_total_malo },
  {name: 'Remenente radicular', label: 'remanente_radicular', img: images.remanente_radicular },
  {name: 'Prótesis removible', label: 'prótesis_removible_bueno', img: images.prótesis_removible_bueno },
  {name: 'Prótesis removible', label: 'prótesis_removible_malo', img: images.prótesis_removible_malo },
  {name: 'Restauración', label: 'restauración', img: images.restauración },
  {name: 'Restauración temporal', label: 'restauración_temporal', img: images.restauración_temporal },
  {name: 'Supernumerario', label: 'supernumerario', img: images.supernumerario },
  {name: 'Transposición', label: 'transposición', img: images.transposición },
  {name: 'Tratamiento pulpar', label: 'tratamiento_pulpar', img: images.tratamiento_pulpar },
  {name: 'Tratamiento pulpar', label: 'tratamiento_pulpar_malo', img: images.tratamiento_pulpar_malo },
  {name: 'Geminación o fusión', label: 'geminación_fusión', img: images.geminación_fusión },
  {name: 'Perno', label: 'perno_bueno', img: images.perno_bueno },
  {name: 'Perno', label: 'perno_malo', img: images.perno_malo },
  {name: 'Sellante', label: 'sellante_bueno', img: images.sellante_bueno },
  {name: 'Sellante', label: 'sellante_malo', img: images.sellante_malo },
]

export {
  theTreatmentIsType,
  getTheElementsOfTheSquares,
  treatments,
  listOfTreatments
}