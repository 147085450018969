export const conventionStore = {
  namespaced: true,
  actions: {
    /* eslint-disable */
    updateConvention ({ state, commit }, [payload, idConvention]) {
      return new Promise((resolve, reject) => {
        axios.post('convention/'+ idConvention, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    registerConvention ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('convention', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateDiscount ({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('discount-treatments', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getConventions ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('convention')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getConventionsWithStateActive ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('convention-active')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getConvention ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('convention/' + id)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}