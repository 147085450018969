import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import Index from '../views/index.vue'

import { UsersRoutes } from '@/router/routes/users.js'
import { ConsultoriesRoutes } from '@/router/routes/consultories'
import { PatientsRoutes } from '@/router/routes/patient'
import { AgreementsRoutes } from '@/router/routes/agreement'
import { DiaryRoutes } from '@/router/routes/diary'
import { LaboratoriesRoutes } from '@/router/routes/laboratory'
import { KardexRoutes } from '@/router/routes/kardex'
import { SettingsRoutes } from '@/router/routes/setting'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: Index,
    redirect: '/diary',
    children: [
      ...DiaryRoutes,
      ...UsersRoutes,
      ...ConsultoriesRoutes,
      ...AgreementsRoutes,
      ...KardexRoutes,
      ...LaboratoriesRoutes, //no working
      ...PatientsRoutes,
      ...SettingsRoutes,
    ]
  },
  {
    name: 'notfound',
    path: '*',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setLoadingRouter', true)
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const accessToken = localStorage.getItem('jwt')
    const domain = localStorage.getItem('domain')

    if (accessToken && domain) {
      next()
      return
    }

    next("/login")
  } else {
    next()
  }
})

router.afterEach(() => {
  setTimeout(() => {
    store.commit('setLoadingRouter', false)
  }, 2000);
})

export default router
