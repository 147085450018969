export const specialtysStore = {
  namespaced: true,
  actions: {
    /* eslint-disable */
    getSpecialties ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('specialty')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}