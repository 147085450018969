import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#338880',
        secondary: '#529CAE',
        accent: '#e8f5e9',
        success: '#56ca00',
        info: '#16b1ff',
        warning: '#ffb400',
        error: '#ff4c51',
        anchor: '#9155fd',
      },
    },
  },
});
