export const SettingsRoutes = [
  {
    path: '/setting',
    name: 'setting',
    component: () => import(/* webpackChunkName: "Settings index" */ '@/views/setting/index.vue'),
    redirect: {name: 'setting.treatment'},
    children: [
      {
        path: 'treatments/:id?',
        name: 'setting.treatment',
        component: () => import(/* webpackChunkName: "Treatment" */ '@/views/setting/Treatment.vue'),
        meta: {
          requiresAuth: true,
          permissions: [
            {
              role: "ADMINISTRADOR",
              access: true
            },
            {
              role: "ASISTENTE ADMINISTRADOR",
              access: true,
            },
            {
              role: "ASISTENTE MÉDICO",
              access: false,
              redirect: "home"
            },
            {
              role: "DOCTOR",
              access: true,
            },
            {
              role: "UNAUTHORIZED",
              access: false,
              redirect: "login"
            }
          ]
        }
      }
    ]
  },
]