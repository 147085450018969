export const consultoryStore = {
  namespaced: true,
  actions: {
    /* eslint-disable */
    getConsultories ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('consultorys')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getConsultoriesWithStateActive ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('consultorys-active')
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getConsultory ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('consultorys/'+ id)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    registerConsultory ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post('consultorys', payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    updateConsultory ({ commit },[payload, idConsultory]) {
      return new Promise((resolve, reject) => {    
        axios.post('consultorys/'+idConsultory, payload)
        .then(res => {
          if (res.status === 200) {
            resolve(res.data)
          } else {
            reject(res.statusText)
          }
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
}